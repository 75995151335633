import React, { useEffect, useRef } from 'react';
import { AnimateElement } from './Function';
import { I_Next, I_Play, I_Pause } from '../UI/IconPack';

export const PlayButton = ({ isPlaying, togglePlay }) => {
    const playIcon = useRef(null);
    const pauseIcon = useRef(null);

    useEffect(() => {
        if (isPlaying) {
            AnimateElement(playIcon.current, 'PLAYER_S_B-HIDE', 0.2);
            AnimateElement(pauseIcon.current, 'PLAYER_S_B-SHOW', 0.2);
        } else {
            AnimateElement(playIcon.current, 'PLAYER_S_B-SHOW', 0.2);
            AnimateElement(pauseIcon.current, 'PLAYER_S_B-HIDE', 0.2);
        }
    }, [isPlaying]);

    return (
        <button onClick={togglePlay} className="UI-PlayButton">
            <I_Play className="PlayIcon" ref={playIcon} />
            <I_Pause className="PauseIcon" ref={pauseIcon} />
        </button>
    );
};

export const BackButton = ({ playerBack }) => {
    return (
        <button onClick={playerBack} className="Back">
            <I_Next />
        </button>
    );
}

export const NextButton = ({ playerNext }) => {
    return (
        <button onClick={playerNext} className="Next">
            <I_Next />
        </button>
    );
};

export const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};