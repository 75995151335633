import { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { I_Back, I_Send, I_Lock, I_Plus, I_AddFile, I_CLOCK, I_CLOSE, I_DOWNLOAD } from '../../../System/UI/IconPack';
import { HandleAvatar, HandleFileIcon, HandleFileSize } from '../../../System/Elements/Handlers';
import { useAccountData } from '../../../System/Elements/AccountManager';
import { aesDecrypt, aesDecryptFile } from '../../../System/Modules/Crypto';
import { PreloadMessages } from '../../../System/UI/Preload';
import { NavLink } from 'react-router-dom';
import { SavesAvatar } from '../../../System/Modules/UIKit';
import { AnimatePresence, motion } from 'framer-motion';
import ScrollableFeed from 'react-scrollable-feed';
import { ProgressRing } from '../../../System/Modules/UIKit'
import classNames from 'classnames';
import { useModal } from '../../../System/Context/Modal';
import useDataBase from '../../../System/Hooks/useDataBase';

const HandleUserStatus = ({ status }) => {
    const [active, setActive] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        switch (status) {
            case 'online':
                setActive(true);
                setText('в сети');
                break;
            case 'offline':
                setActive(false);
                setText('не в сети');
                break;
            default:
                setActive(false);
                setText('неизвестен');
        }
    }, [status]);

    return (
        <div className="Chat-Status" style={active ? { color: 'var(--ACCENT_COLOR)' } : {}}>
            {text}
        </div>
    );
};

const HandleMessageImage = ({ message, decrypted, socket }) => {
    const { isDbReady, getData } = useDataBase('element', 1);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [fileSrc, setFileSrc] = useState(null);

    const loadFile = async () => {
        if (message?.mid) {
            const file = await getData('files', message.mid);

            if (file) {
                setFileSrc(URL.createObjectURL(file.blob));
                setIsDownloaded(true);
                return true;
            } else {
                return false;
            }
        }
    }

    useEffect(() => {
        if (isDbReady) {
            loadFile();
        }
        if (message?.is_uploaded) {
            setIsDownloaded(true);
            setFileSrc(decrypted.file.base64)
        }
    }, [isDbReady, message?.is_downloaded, message?.is_uploaded]);

    const download = async () => {
        const file = await loadFile();

        if (!file) {
            socket.send({
                type: 'download_file',
                mid: message.mid,
                file_map: decrypted.file.file_map
            });
        }
    }

    return (
        <div className="Image">
            {
                message.status === 'not_sent' ? (
                    <button className="Loader">
                        <ProgressRing progress={message.upload_progress} />
                        <I_CLOSE />
                    </button>
                ) : (
                    <>
                        <div
                            className={classNames('Bum', { 'BumBum': isDownloaded })}
                        ></div>
                        <AnimatePresence>
                            {
                                !isDownloaded && (
                                    <motion.button
                                        className="Loader"
                                        onClick={download}
                                        initial={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >
                                        <I_DOWNLOAD />
                                    </motion.button>
                                )
                            }
                        </AnimatePresence>
                    </>
                )
            }
            <motion.img
                className={!isDownloaded ? 'NotLoaded' : ''}
                style={{ width: decrypted?.preview?.width }}
                src={fileSrc ? fileSrc : decrypted.preview.base64}
            />
        </div>
    );
}

const HandleMessage = ({ message, socket, stopSendingFile }) => {
    try {
        const accountData = useAccountData();
        const messageTime = new Date(message.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const decrypted = message?.is_json === true ? message.decrypted : JSON.parse(message.decrypted);

        return (
            <div className={message.uid === accountData.ID ? 'Chat-M_Me' : 'Chat-M_URS'}>
                <>
                    {decrypted?.type === 'file' && (
                        <div className="File">
                            {message.status === 'not_sent' ? (
                                <button onClick={() => { stopSendingFile(message.temp_mid) }} className="Loader">
                                    <ProgressRing
                                        progress={message.upload_progress ? message.upload_progress : 1}
                                    />
                                    <I_CLOSE />
                                </button>
                            ) : (
                                <div className="Icon">
                                    <HandleFileIcon fileName={decrypted.file.name} />
                                </div>
                            )}
                            <div className="Metadata">
                                <div className="Name">{decrypted.file.name}</div>
                                <div className="Size">
                                    <HandleFileSize bytes={decrypted.file.size} />
                                </div>
                            </div>
                        </div>
                    )}
                    {decrypted?.type === 'image' && (
                        <HandleMessageImage message={message} decrypted={decrypted} socket={socket} />
                    )}
                </>
                <div className="TextAndStatus">
                    <div className="Text">
                        {decrypted?.text}
                    </div>
                    <div className="Status">
                        <div className="Time">{messageTime}</div>
                        {
                            message.status === 'not_sent' && (
                                <I_CLOCK />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        console.error('Произошла ошибка при обработке сообщения: ', error);
        return null;
    }
}

const Chat = ({ socket, selectedChat, keyword }) => {
    const { openModal } = useModal();
    const { db, error, addData, getData, updateData, deleteData } = useDataBase('element', 1);
    const accountData = useAccountData();
    const [chatDataLoaded, setChatDataLoaded] = useState(false);
    const [messagesSI, setMessagesSI] = useState(25);
    const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageValue, setMessageValue] = useState('');
    const [actionPanelOpen, setActionPanelOpen] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);

    // Файловый инпут
    const fileInputRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const { ref: loadMoreRef, inView: inView } = useInView({
        threshold: 0
    });

    // Отправка файла
    const CHUNK_SIZE = 10 * 1024;
    const currentChunk = useRef(0);

    const apVariants = {
        show: {
            opacity: 1,
            backdropFilter: 'blur(6px)',
            y: 0,
            x: 0,
            transition: { duration: 0.4 }
        },
        hide: {
            opacity: 0,
            backdropFilter: 'blur(0px)',
            y: 200,
            x: -200,
            transition: { duration: 0.4 }
        }
    }

    // Индекс
    useEffect(() => {
        if (socket.ready) {
            socket.onMessage((data) => {
                console.log(data);
                switch (data.type) {
                    case 'load_chat':
                        loadChat(data);
                        break;
                    case 'load_messages':
                        loadMessages(data.content)
                        break;
                    case 'new_message':
                        handleNewMessage(data);
                        break;
                    case 'send_message':
                        handleSendMessage(data);
                        break;
                    case 'download_file':
                        handleDownloadFile(data);
                        break;
                }
            });
        }
    }, [socket])

    useEffect(() => {
        if (inView) {
            loadMoreMessages();
        }
    }, [inView]);

    useEffect(() => {
        if (messagesLoaded) {
            const timer = setTimeout(() => {
                setShowLoadMore(true);
            }, 3000);

            return () => clearTimeout(timer);
        } else {
            setShowLoadMore(false);
        }
    }, [messagesLoaded]);

    useEffect(() => {
        console.log('Обновление сообщений ', messages);
    }, [messages])

    useEffect(() => {
        setMessagesLoaded(false);
        setMessagesSI(25);
        setMessages([]);
    }, [selectedChat])

    const updateMessagesByMID = ({ mid, newData }) => {
        setMessages(prevMessages =>
            prevMessages.map(message =>
                message.mid === mid ? { ...message, ...newData } : message
            )
        );
    };

    const updateMessagesByTempMID = ({ temp_mid, newData }) => {
        setMessages(prevMessages =>
            prevMessages.map(message =>
                message.temp_mid === temp_mid ? { ...message, ...newData } : message
            )
        );
    };

    const deleteMessagesByTempMID = (temp_id) => {
        setMessages(prevMessages =>
            prevMessages.filter(message => message.temp_mid !== temp_id)
        );
    };

    const createTempMesID = (length) => {
        while (true) {
            let id = '';
            for (let i = 0; i < length; i++) {
                id += Math.floor(Math.random() * 10);
            }
            const answer = messages.find(m => m?.temp_mid === id);
            if (!answer) {
                return Number(id);
            }
        }
    }

    // Выгрузка файла
    const sendFile = ({ temp_mid, file }) => {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        currentChunk.current = 0;

        const controller = new AbortController();
        const signal = controller.signal;

        const sendNextChunk = () => {
            if (signal.aborted) {
                return;
            }

            const start = currentChunk.current * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end);

            const reader = new FileReader();
            reader.onload = (event) => {

                socket.send({
                    type: 'upload_file',
                    temp_mid: temp_mid,
                    current_chunk: currentChunk.current,
                    total_chunks: totalChunks,
                    binary: new Uint8Array(event.target.result)
                });

                console.log(currentChunk);
                console.log(totalChunks);

                currentChunk.current++;

                const uploadProgress = (currentChunk.current / totalChunks) * 100;

                updateMessagesByTempMID({
                    temp_mid: temp_mid,
                    newData: {
                        upload_progress: uploadProgress
                    }
                })

                if (currentChunk.current < totalChunks) {
                    sendNextChunk();
                } else {
                    currentChunk.current = 0;
                    console.log('Отправлено');
                }
            };

            reader.readAsArrayBuffer(chunk);
        };

        sendNextChunk();

        const cancelSend = () => {
            controller.abort();
        };

        return cancelSend;
    }

    const stopSendingFile = (tempMid) => {
        const message = messages.find(m => m.temp_mid === tempMid);
        message.stop();

        socket.send({
            type: 'upload_file',
            temp_mid: tempMid,
            action: 'stop_upload'
        });

        deleteMessagesByTempMID(tempMid);
        setUploadFiles([]);
    }

    const handleSendMessage = (data) => {
        switch (data.status) {
            case 'awaiting_file':
                const currentFile = uploadFiles.find(file => file.temp_mid === data.temp_mid);

                const stop = sendFile({
                    temp_mid: Number(data.temp_mid),
                    file: currentFile.file
                })

                updateMessagesByTempMID({
                    temp_mid: Number(data.temp_mid),
                    newData: {
                        upload_progress: 0,
                        stop: stop
                    }
                })
                break;

            case 'sended':
                updateMessagesByTempMID({
                    temp_mid: data.temp_mid,
                    newData: {
                        mid: data.mid,
                        status: 'sended'
                    }
                })
                break;

            case 'error':
                deleteMessagesByTempMID(data.temp_mid);
                openModal({
                    type: 'info',
                    title: 'Ошибка',
                    text: data.text
                })
                break
        }
    }

    // Скачивание файла
    const handleDownloadFile = async (data) => {
        switch (data.status) {
            case 'download':
                let downloading = await getData('downloads', data.mid);
                const unit8Array = new Uint8Array(Object.values(data.binary));

                if (downloading) {
                    const chunk = downloading.chunks.find(chunk => chunk.id === data.file_id);

                    if (!chunk) {
                        await updateData('downloads', {
                            ...downloading,
                            mid: data.mid,
                            downloaded: [...downloading.downloaded, data.file_id],
                            not_downloaded: downloading.map.filter(chunk => !downloading.downloaded.includes(chunk.id)),
                            chunks: [
                                ...downloading.chunks,
                                {
                                    id: data.file_id,
                                    binary: unit8Array
                                }
                            ]
                        })
                    }
                } else {
                    const message = messages.find(item => item.mid === data.mid);
                    const decrypted = JSON.parse(message.decrypted);
                    const fileMap = decrypted.file.file_map;

                    await addData('downloads', {
                        mid: data.mid,
                        name: decrypted.file.name,
                        map: fileMap,
                        encrypted_key: decrypted.file.encrypted_key,
                        encrypted_iv: decrypted.file.encrypted_iv,
                        downloaded: [data.file_id],
                        not_downloaded: 0,
                        chunks: [
                            {
                                id: data.file_id,
                                binary: unit8Array
                            }
                        ]
                    })
                }

                downloading = await getData('downloads', data.mid);
                
                if (downloading.downloaded.length === downloading.map.length) {
                    let chunks = [];

                    for (const fileID of downloading.map) {
                        const fileChunk = downloading.chunks.find(chunk => chunk.id === fileID);
                        if (fileChunk) {
                            chunks.push(new Uint8Array(fileChunk.binary));
                        }
                    }

                    const totalLength = chunks.reduce((acc, chunk) => acc + chunk.length, 0);
                    const completeFile = new Uint8Array(totalLength);
                    let offset = 0;
                
                    for (const chunk of chunks) {
                        completeFile.set(chunk, offset);
                        offset += chunk.length;
                    }
                
                    const decryptedFile = await aesDecryptFile(completeFile, downloading.encrypted_key, downloading.encrypted_iv);
                    const file = new Blob([decryptedFile], { type: 'application/octet-stream' });
                    await updateData('files', {
                        mid: data.mid,
                        blob: file
                    })
                    updateMessagesByMID({
                        mid: data.mid,
                        newData: {
                            is_downloaded: true
                        }
                    })
                } else {
                    /*socket.send({
                        type: 'download_file',
                        mid: data.mid,
                        file_map: downloading.not_downloaded
                    });*/
                }
                break;
        }
    }

    const loadChat = (data) => {
        setChatDataLoaded(true);
        socket.send({
            type: 'load_messages',
            uid: data.content.userdata.id
        });
    }

    const handleNewMessage = (data) => {
        if (selectedChat && selectedChat.userdata.id === data.uid) {
            const newMessage = {
                mid: data.mid,
                uid: data.uid,
                decrypted: data.message,
                date: data.date
            }
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }
    }

    const loadMessages = async (dataMessages) => {

        if (!Array.isArray(dataMessages) || !dataMessages.length) {
            setMessagesLoaded(true);
            return;
        }

        const decryptedMessages = await Promise.all(dataMessages.map(async (message) => {
            try {
                const decrypted = message.encrypted
                    ? await aesDecrypt(new TextEncoder().encode(message.encrypted), keyword)
                    : message.decrypted;
                return {
                    ...message,
                    decrypted: decrypted,
                };
            } catch (error) {
                console.error('Ошибка расшифровки сообщения:', error);
                return message;
            }
        }));

        setMessages((prevMessages) =>
            prevMessages.length > 0 ? [...prevMessages, ...decryptedMessages] : decryptedMessages
        );
        setMessagesLoaded(true);
    };

    const loadMoreMessages = () => {
        if (socket.ready && selectedChat) {
            socket.send({
                type: 'load_messages',
                uid: selectedChat.userdata.id,
                startIndex: messagesSI
            });
            setMessagesSI(prev => prev + 25);
        }
    }

    // Отправка сообщений

    const sendMessage = async () => {
        let selectedFile;

        if (messageValue.trim() !== '') {
            const temp_mid = createTempMesID(10);

            if (files.length > 0) {

                socket.send({
                    type: 'send_message',
                    temp_mid: temp_mid,
                    uid: selectedChat.userdata.id,
                    message: messageValue,
                    files: [
                        {
                            name: files[0].name,
                            type: files[0].type,
                            size: files[0].size,
                        }
                    ]
                });

                const newFile = {
                    temp_mid: temp_mid,
                    file: files[0]
                }

                setUploadFiles(prevFiles => [...prevFiles, newFile]);
                selectedFile = files[0];
            } else {
                socket.send({
                    type: 'send_message',
                    temp_mid: temp_mid,
                    uid: selectedChat.userdata.id,
                    message: messageValue
                });
            }

            const handleFileType = () => {
                if (selectedFile) {
                    switch (selectedFile.type) {
                        case 'image/jpeg':
                        case 'image/png':
                            return 'image';
                        default:
                            return 'file';
                    }
                } else {
                    return 'text'
                }
            }

            const fileToB64 = (file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;

                    if (file) {
                        reader.readAsDataURL(file);
                    }
                });
            };

            const messageType = handleFileType();
            let newMessage = {
                temp_mid: temp_mid,
                status: 'not_sent',
                uid: accountData.ID,
                is_json: true,
                is_uploaded: true,
                decrypted: {
                    text: messageValue,
                    type: messageType
                },
                date: new Date()
            }
            if (messageType === 'image') {
                const base64 = await fileToB64(selectedFile);
                newMessage.decrypted.preview = { base64: base64 };
                newMessage.decrypted.file = { base64: base64 };
            }
            if (messageType === 'file') {
                newMessage.decrypted.file = {
                    name: selectedFile.name,
                    type: selectedFile.type,
                    size: selectedFile.size
                }
            }
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setMessageValue('');
            setFiles([]);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        setFiles(Array.from(files));
    };

    return (
        <>
            <div className="Chat-Messages">
                <ScrollableFeed className="Chat-Messages_scroll">
                    <div className="Chat-Messages_list">
                        {
                            messagesLoaded ? (
                                <>
                                    {showLoadMore && <span ref={loadMoreRef}></span>}
                                    {
                                        messages.length > 0 ? (
                                            messages.sort((b, a) => new Date(a.date) - new Date(b.date)).reverse().map((message, i) => (
                                                <HandleMessage key={i} message={message} stopSendingFile={stopSendingFile} socket={socket} />
                                            ))
                                        ) : (
                                            <div className="Chat-NonMessages">
                                                <I_Lock />
                                                <div>Ваш чат защищён end to end шифрованием, а так же вашим ключом, даже создатель Элемента не сможет видеть о чём вы говорите.</div>
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <PreloadMessages />
                            )
                        }
                    </div>
                </ScrollableFeed>
            </div>
            <div className="Chat-TopBar">
                <div className="Back">
                    <I_Back />
                </div>
                <div className="Chat-TB_Data">
                    <div className="Chat-Name">
                        {
                            chatDataLoaded ? (
                                selectedChat.userdata.saves === true ? (
                                    'Избранное'
                                ) : (
                                    <NavLink to={`/e/${selectedChat.userdata.username}`}>
                                        {selectedChat.userdata.name}
                                    </NavLink>
                                )
                            ) : (
                                <div className="UI-PRELOAD" style={{ width: '100px', height: '15px' }}></div>
                            )
                        }
                    </div>
                    {
                        chatDataLoaded ? (
                            selectedChat.userdata.saves === false && (
                                <HandleUserStatus status={selectedChat.userdata.status} />
                            )
                        ) : (
                            <div className="Chat-Status">
                                <div className="UI-PRELOAD" style={{ width: '80px', height: '10px' }}></div>
                            </div>
                        )
                    }
                </div>
                {
                    chatDataLoaded ? (
                        selectedChat.userdata.saves === true ? (
                            <SavesAvatar />
                        ) : (
                            <NavLink to={`/e/${selectedChat.userdata.username}`} className="Avatar">
                                <HandleAvatar avatar={selectedChat.userdata.avatar} name={selectedChat.userdata.name} />
                            </NavLink>
                        )
                    ) : (
                        <div className="Avatar">
                            <div className="UI-PRELOAD"></div>
                        </div>
                    )
                }
            </div>
            <div className="Chat-SelectedFiles">
                {
                    files.length > 0 && (
                        files.map((file, i) => (
                            <div key={i} className="File">
                                <HandleFileIcon fileName={file.name} />
                                <div className="Metadata">
                                    <div className="Name">{file.name}</div>
                                    <div className="Size">
                                        <HandleFileSize bytes={file.size} />
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                }
            </div>
            <div className="Chat-DownBar">
                <button onClick={() => { setActionPanelOpen(true) }}>
                    <I_Plus />
                </button>
                <input
                    style={{ display: 'none' }}
                    id="M-FileInput"
                    onChange={handleFileInputChange}
                    type="file"
                    ref={fileInputRef}
                />
                <input
                    value={messageValue}
                    onChange={(e) => setMessageValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="Chat-Input"
                    type="text"
                    placeholder="Введите сообщение.."
                />
                <button onClick={sendMessage} className="Send">
                    <I_Send />
                </button>
            </div>
            <AnimatePresence>
                {
                    actionPanelOpen && (
                        <motion.div
                            className="ActionPanel"
                            variants={apVariants}
                            initial="hide"
                            animate="show"
                            exit="hide"
                        >
                            <div
                                onClick={() => { setActionPanelOpen(false) }}
                                style={{ width: '100%', height: '100%', position: 'absolute' }}
                            ></div>
                            <div className="Buttons">
                                <label htmlFor="M-FileInput">
                                    <I_AddFile />
                                    Выбрать файл
                                </label>
                            </div>
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </>
    )
}

export default Chat;
