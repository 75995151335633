import { useState, useEffect } from 'react';
import { arrayBufferToPem, rsaEncrypt, rsaDecrypt, aesCreateKey, aesEncrypt, aesDecrypt, blobToUint8Array } from '../Modules/Crypto';
import { WS_DOMAIN } from '../Elements/AccountManager';

const useWS = () => {
    const [socketStatus, setSocketStatus] = useState('Отключено');
    const [socket, setSocket] = useState(null);
    const [socketOpen, setSocketOpen] = useState(false);
    const [keysReady, setKeysReady] = useState(false);
    const [rsaPublic, setRsaPublic] = useState(null);
    const [rsaPrivate, setRsaPrivate] = useState(null);
    const [rsaPublicServer, setRsaPublicServer] = useState(null);
    const [aesKey, setAesKey] = useState(null);
    const [aesServerKey, setAesServerKey] = useState(null);
    const [socketReady, setSocketReady] = useState(false);
    const [messageQueue, setMessageQueue] = useState([]);

    const socketQuery = async (json) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            const jsonData = JSON.stringify(json);
            if (rsaPublicServer) {
                if (aesServerKey) {
                    const encryptedData = await aesEncrypt(jsonData, aesServerKey);
                    socket.send(encryptedData);
                } else {
                    const encryptedData = await rsaEncrypt(jsonData, rsaPublicServer);
                    socket.send(encryptedData);
                }
            } else {
                socket.send(jsonData);
            }
        }
    };

    const handleRequest = async (e) => {
        if (rsaPublicServer) {
            if (aesServerKey) {
                const data = JSON.parse(await aesDecrypt(await blobToUint8Array(e.data), aesKey));
                setMessageQueue((prev) => [...prev, data]);
            } else {
                const data = JSON.parse(await rsaDecrypt(await blobToUint8Array(e.data), rsaPrivate));
                switch (data.type) {
                    case 'auth':
                        const key = aesCreateKey(rsaPublic);
                        setAesKey(key);
                        socketQuery({ type: 'aes_key', key });
                        break;
                    case 'aes_key':
                        setAesServerKey(data.key);
                        setSocketReady(true);
                        break;
                    default:
                        setMessageQueue((prev) => [...prev, data]);
                        break;
                }
            }
        } else {
            const data = JSON.parse(e.data);
            if (data.type === 'key_exchange') {
                setRsaPublicServer(data.key);
            }
        }
    };

    const generateKeyPair = async () => {
        const keyPair = await window.crypto.subtle.generateKey({
            name: 'RSA-OAEP',
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: { name: 'SHA-256' }
        }, true, ['encrypt', 'decrypt']);
        setRsaPublic(await window.crypto.subtle.exportKey('spki', keyPair.publicKey));
        setRsaPrivate(await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey));
        setKeysReady(true);
    };

    useEffect(() => {
        if (!keysReady) {
            setSocketStatus('Создание ключей...')
            generateKeyPair();
        }
    }, [keysReady]);

    useEffect(() => {
        let connection;

        const connect = () => {
            if (!connection) {
                setSocketStatus('Соединение...')
                connection = new WebSocket(WS_DOMAIN);
                connection.onopen = () => {
                    setSocket(connection);
                    setSocketOpen(true);
                };
                connection.onclose = () => {
                    setSocket(null);
                    setSocketOpen(false);
                    setKeysReady(false);
                    setRsaPublic(null);
                    setRsaPrivate(null);
                    setRsaPublicServer(null);
                    setAesKey(null);
                    setAesServerKey(null);
                    setSocketReady(false);
                    setTimeout(connect, 10000);
                };
                connection.onerror = () => {
                    connection.close();
                };
            }
        };

        if (keysReady) {
            connect();
        }

        return () => {
            if (connection) {
                connection.close();
            }
        };
    }, [keysReady]);

    useEffect(() => {
        if (socket && socketOpen) {
            socket.onmessage = (e) => handleRequest(e);
            return () => {
                socket.onmessage = null;
            };
        }
    }, [socket, socketOpen, rsaPublicServer, aesServerKey]);

    useEffect(() => {
        if (socket && socketOpen) {
            socketQuery({
                type: 'key_exchange',
                key: arrayBufferToPem(rsaPublic, 'PUBLIC KEY')
            });
        }
    }, [socket, socketOpen]);

    useEffect(() => {
        if (rsaPublicServer) {
            setSocketStatus('Авторизация...')
            socketQuery({
                type: 'auth',
                S_KEY: localStorage.getItem('S_KEY')
            });
        }
    }, [rsaPublicServer]);

    return {
        send: (message) => socket && socketQuery(message),
        onMessage: (callback) => {
            if (socketReady && messageQueue.length > 0) {
                messageQueue.forEach((msg) => callback(msg));
                setMessageQueue([]);
            }
        },
        status: socketStatus,
        ready: socketReady
    };
};

export default useWS;