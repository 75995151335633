import { NavButton } from '../../../System/Elements/Navigate';
import { motion } from 'framer-motion';
import { PreloadChats } from '../../../System/UI/Preload';
import { HandleAvatar } from '../../../System/Elements/Handlers';
import { SavesAvatar } from '../../../System/Modules/UIKit';
import LottieAnimation from '../../../System/Modules/LotteAnimation';
import L_Sorry from '../../../System/UI/Lottie/Sorry.json';

const HandleChat = ({ chatData, selectedChat }) => {
    return (
        <NavButton to={`/chat/${chatData.username}`} className={`Chats-User${chatData.uid === selectedChat?.userdata?.id ? ' Chats-UserSelected' : ''}`}>
            {chatData.saves ? (
                <SavesAvatar />
            ) : (
                <div className="Avatar">
                    <HandleAvatar avatar={chatData.avatar} name={chatData.name} />
                </div>
            )
            }
            <div className="Chats-NandLM">
                <div className="Chats-Name">{chatData.saves ? 'Избранное' : chatData.name}</div>
                <div className="Chats-LastMessage">{chatData.last_message}</div>
            </div>
            {chatData.notifications > 0 && <div className="UI-NCounter">{chatData.notifications}</div>}
        </NavButton>
    )
}

const Chats = ({ socketReady, socketStatus, chatsLoaded, chats, selectedChat }) => {
    const animations = {
        show: {
            opacity: [0, 1],
            filter: ['blur(1px)', 'blur(0px)'],
            transition: { duration: 0.2 },
            transitionEnd: {
                visibility: 'visible'
            }
        },
        hide: {
            opacity: [1, 0],
            filter: ['blur(1px)', 'blur(0px)'],
            transition: { duration: 0.2 },
            transitionEnd: {
                visibility: 'hidden'
            }
        }
    };

    return (
        <div className="Chats">
            <div className="Chats-Title">
                {
                    socketReady ? (
                        <motion.div
                            className="Title"
                            initial="hide"
                            animate="show"
                            variants={animations}
                        >
                            Чаты
                        </motion.div>
                    ) : (
                        <motion.div
                            className="Connection"
                            initial="hide"
                            animate="show"
                            variants={animations}
                        >
                            <div className="UI-Loader_1"></div>
                            <div>{socketStatus}</div>
                        </motion.div>
                    )
                }
            </div>
            <div className="Chats-List_scroll">
                <div className="Chats-List">
                    {
                        chatsLoaded ? (
                            chats.length && chats.length > 0 ? (
                                chats.sort((b, a) => new Date(a.last_message_date) - new Date(b.last_message_date)).map((chat, i) => (
                                    <HandleChat key={i} chatData={chat} selectedChat={selectedChat} />
                                ))
                            ) : (
                                <div className="UI-ErrorMessage">
                                    <LottieAnimation className="Emoji" lottie={L_Sorry} />
                                    Ааа эээ, у вас нет чатов
                                </div>
                            )
                        ) : (
                            <PreloadChats />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Chats;