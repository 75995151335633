import CryptoJS from 'crypto-js';

// Шифрование end to end

// Функция для преобразования ArrayBuffer в PEM формат
export const arrayBufferToPem = (buffer, type) => {
    const binary = String.fromCharCode.apply(null, new Uint8Array(buffer));
    const base64 = btoa(binary);
    let pem = `-----BEGIN ${type}-----\n`;
    pem += base64.match(/.{1,64}/g).join('\n');
    pem += `\n-----END ${type}-----\n`;
    return pem;
}

// Импорт публичного ключа
export const importPublicKey = (pk) => {
    const base64String = pk
        .replace(/-----BEGIN PUBLIC KEY-----/, '')
        .replace(/-----END PUBLIC KEY-----/, '')
        .replace(/\s/g, '');
    const publicKeyBytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    const publicKeyBuffer = publicKeyBytes.buffer;
    return publicKeyBuffer;
}
// Импорт приватного ключа
export const importPrivateKey = (pk) => {
    const base64String = pk
        .replace(/-----BEGIN PRIVATE KEY-----/, '')
        .replace(/-----END PRIVATE KEY-----/, '')
        .replace(/\s/g, '');
    const privateKeyBytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
    const privateKeyBuffer = privateKeyBytes.buffer;
    return privateKeyBuffer;
}
// Шифрование
export const rsaEncrypt = async (data, pk) => {
    try {
        const publicKeyBuffer = importPublicKey(pk);
        const dataBuffer = new TextEncoder().encode(data);
        const publicKeyE = await crypto.subtle.importKey(
            'spki',
            publicKeyBuffer,
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            true,
            ['encrypt']
        );
        const encryptedDataBuffer = await crypto.subtle.encrypt(
            { name: 'RSA-OAEP' },
            publicKeyE,
            dataBuffer
        );
        return new Uint8Array(encryptedDataBuffer);
    } catch (error) {
        throw new Error('Ошибка шифрования данных: ' + error.message);
    }
}
// Расшифровка
export const rsaDecrypt = async (data, pk) => {
    try {
        const privateKeyBuffer = pk;
        const privateKeyE = await crypto.subtle.importKey(
            'pkcs8',
            privateKeyBuffer,
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            true,
            ['decrypt']
        );
        const decryptedDataBuffer = await crypto.subtle.decrypt(
            { name: 'RSA-OAEP' },
            privateKeyE,
            data
        );
        const decryptedDataString = new TextDecoder().decode(decryptedDataBuffer);
        return decryptedDataString;
    } catch (error) {
        throw new Error('Ошибка расшифровки данных: ' + error.message);
    }
}

// AES шифрование

// Создание ключа из слова
export const aesCreateKey = (word) => {
    const hash = CryptoJS.SHA256(word);
    const key = hash.toString(CryptoJS.enc.Hex).slice(0, 32);
    return key;
}

// Шифрование
export const aesEncrypt = async (data, key) => {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);
    try {
        const importedKey = await crypto.subtle.importKey(
            'raw',
            Uint8Array.from(key),
            { name: 'AES-CBC' },
            false,
            ['encrypt', 'decrypt']
        );
        const cipher = crypto.subtle.encrypt(
            {
                name: 'AES-CBC',
                iv: iv
            },
            importedKey,
            encodedData
        );
        return cipher.then(encryptedBuffer => {
            const ivBase64 = arrayBufferToBase64(iv);
            const encryptedDataBase64 = arrayBufferToBase64(encryptedBuffer);
            return new TextEncoder().encode(ivBase64 + ':' + encryptedDataBase64);
        });
    } catch (error) {
        console.error("Ошибка при шифровании:", error);
        return null;
    }
}

// Расшифровка
export const aesDecrypt = async (data, key) => {
    const parts = new TextDecoder().decode(data).split(':');
    const iv = base64ToArrayBuffer(parts[0]);
    const encrypted = base64ToArrayBuffer(parts[1]);
    const importedKey = await crypto.subtle.importKey(
        'raw',
        Uint8Array.from(key),
        { name: 'AES-CBC' },
        false,
        ['encrypt', 'decrypt']
    );
    const decryptedBuffer = await crypto.subtle.decrypt(
        {
            name: 'AES-CBC',
            iv: iv
        },
        importedKey,
        encrypted
    );
    const decryptedDataString = new TextDecoder().decode(decryptedBuffer);
    return decryptedDataString;
}

export const aesDecryptFile = async (file, keyB64, ivB64) => {
    const key = base64ToArrayBuffer(keyB64);
    const iv = base64ToArrayBuffer(ivB64);

    try {
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            key,
            { name: 'AES-CBC' },
            false,
            ['decrypt']
        );

        const decrypted = await crypto.subtle.decrypt(
            {
                name: 'AES-CBC',
                iv: iv
            },
            cryptoKey,
            file
        );

        return new Uint8Array(decrypted);
    } catch (error) {
        console.error('Ошибка расшифровки данных:', error);
        throw error;
    }
}

// Разные доп функции
export const blobToUint8Array = async (blob) => {
    const arrayBuffer = await blob.arrayBuffer();
    return new Uint8Array(arrayBuffer);
}

export const arrayBufferToBase64 = (buffer) => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const base64ToArrayBuffer = (base64) => {
    const binaryString = atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}