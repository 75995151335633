import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { HandleTimeAge, HandleFileSize, HandleFileIcon } from '../System/Elements/Handlers';
import { I_Comment, I_Dislike, I_INFO, I_Like } from '../System/UI/IconPack';
import GoldUsers from '../System/Elements/GoldUsers';

function Modal({ Title, Content }) {
  alert(`${Title}: ${Content}`);
}

const HandleEPACKAvatar = ({ avatar, name }) => {
  if (avatar) {
    return <img src={`data:image/jpeg;base64,${avatar}`} />;
  } else {
    return <div className="NonAvatar">{name[0] || '?'}</div>;
  }
};

const HandleEPACKComment = ({ eVer, comment }) => {
  return (
    <>
      {
        (eVer === '1.4' || eVer === '1.9.2' || eVer === '1.9.4') && (
          <div className="UI-Block Comment">
            <div className="TopBar">
              <div className="Info">
                <NavLink to={`/e/${comment.Username}`} className="Avatar">
                  <HandleEPACKAvatar avatar={comment.Avatar} name={comment.Name} />
                </NavLink>
                <div>
                  <NavLink to={`/e/${comment.Username}`} className="Name">
                    {comment.Name}
                  </NavLink>
                  <div className="Date">
                    <HandleTimeAge inputDate={comment.Date} />
                  </div>
                </div>
              </div>
            </div>
            <div className="Text">{comment.Text}</div>
          </div>
        )
      }
      {
        eVer === '2.1' && (
          <div className="UI-Block Comment">
            <div className="TopBar">
              <div className="Info">
                <NavLink to={`/e/${comment.author_data.username}`} className="Avatar">
                  <HandleEPACKAvatar avatar={comment.author_data.avatar} name={comment.author_data.name} />
                </NavLink>
                <div>
                  <NavLink to={`/e/${comment.author_data.username}`} className="Name">
                    {comment.author_data.name}
                  </NavLink>
                  <div className="Date">
                    <HandleTimeAge inputDate={comment.date} />
                  </div>
                </div>
              </div>
            </div>
            <div className="Text">{comment.text}</div>
          </div>
        )
      }
    </>
  );
};

const HandlePost = ({ post }) => {
  return (
    <>
      {
        post.E_VER === '1.4' && (
          <div className="EPACK-Post Post UI-Block">
            <div className="TopBar">
              <NavLink to={`/e/${post.Username}`}>
                <div className="Avatar">
                  <HandleEPACKAvatar avatar={post.Avatar} name={post.Name} />
                </div>
              </NavLink>
              <div>
                <div className="Name">{post.Name}</div>
                <div className="Date"><HandleTimeAge inputDate={post.Date} /></div>
              </div>
            </div>
            <div className="Text">{post.Text}</div>
            {post.Content && post.Content.Type === 'Image' && (() => {
              const image = 'data:image/jpeg;base64,' + post.Content.Image;
              return (
                <div className="UserContent-Image" img-name={post.Content.Name} img-size={post.Content.Size}>
                  <img className="IMG" src={image} alt={post.Content.Name} />
                  <div className="Blur"></div>
                  <img className="BlurIMG" src={image} alt={post.Content.Name} />
                </div>
              );
            })()}
            {post.Content && post.Content.Type === 'File' && (() => {
              const fileOrigName = post.Content.Name;
              return (
                <div className="UserContent-File">
                  <HandleFileIcon fileName={fileOrigName} />
                  <div className="FileInfo">
                    <div className="FileName">{fileOrigName}</div>
                    <div className="FileSize">
                      <HandleFileSize bytes={post.Content.Size} />
                    </div>
                    <a href={`data:application/octet-stream;base64,${post.Content.File}`} download={fileOrigName}>
                      Скачать
                    </a>
                  </div>
                </div>
              );
            })()}
            <div className="Interaction">
              <div className="InteractionCount">
                <I_Like />
                <div className="Likes">{post.LikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Dislike />
                <div className="Dislikes">{post.DislikesCount}</div>
              </div>
            </div>
          </div>
        )
      }
      {
        (post.E_VER === '1.9.2' || post.E_VER === '1.9.4') && (
          <div className="EPACK-Post Post UI-Block">
            <div className="TopBar">
              <NavLink to={`/e/${post.Username}`}>
                <div className="Avatar">
                  <HandleEPACKAvatar avatar={post.Avatar} name={post.Name} />
                </div>
              </NavLink>
              <div>
                <div className="Name">{post.Name}</div>
                <div className="Date"><HandleTimeAge inputDate={post.Date} /></div>
              </div>
            </div>
            <div className="Text">{post.Text}</div>
            {post.Content &&
              post.Content.Type === 'Image' &&
              (() => {
                const image = 'data:image/jpeg;base64,' + post.Content.ImageB64;
                return (
                  <div
                    className="UserContent-Image"
                    img-name={post.Content.orig_name}
                    img-size={post.Content.file_size}
                  >
                    <img className="IMG" src={image} alt={post.Content.orig_name} />
                    <div className="Blur"></div>
                    <img className="BlurIMG" src={image} alt={post.Content.orig_name} />
                  </div>
                );
              })()}
            {post.Content &&
              post.Content.Type === 'File' &&
              (() => {
                const fileOrigName = post.Content.orig_name;
                return (
                  <div className="UserContent-File">
                    <HandleFileIcon fileName={fileOrigName} />
                    <div className="FileInfo">
                      <div className="FileName">{fileOrigName}</div>
                      <div className="FileSize">
                        <HandleFileSize bytes={post.Content.Size} />
                      </div>
                      <a
                        href={`data:application/octet-stream;base64,${post.Content.FileB64}`}
                        download={fileOrigName}
                      >
                        Скачать
                      </a>
                    </div>
                  </div>
                );
              })()}
            <div className="Interaction">
              <div className="InteractionCount">
                <I_Like />
                <div className="Likes">{post.LikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Dislike />
                <div className="Dislikes">{post.DislikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Comment />
                <div className="Comments">{post.CommentsCount}</div>
              </div>
            </div>
          </div>
        )
      }
      {
        (post.E_VER === '2.1') && (
          <div className="EPACK-Post Post UI-Block">
            <div className="TopBar">
              <NavLink to={`/e/${post.author_data.username}`}>
                <div className="Avatar">
                  <HandleEPACKAvatar avatar={post.author_data.avatar} name={post.author_data.name} />
                </div>
              </NavLink>
              <div>
                <div className="Name">{post.author_data.name}</div>
                <div className="Date"><HandleTimeAge inputDate={post.date} /></div>
              </div>
            </div>
            <div className="Text">{post.text}</div>
            {post.content &&
              post.content.type === 'image' &&
              (() => {
                const image = 'data:image/jpeg;base64,' + post.content.file;
                return (
                  <div
                    className="UserContent-Image"
                    img-name={post.content.orig_name}
                    img-size={post.content.file_size}
                  >
                    <img className="IMG" src={image} alt={post.content.orig_name} />
                    <div className="Blur"></div>
                    <img className="BlurIMG" src={image} alt={post.content.orig_name} />
                  </div>
                );
              })()}
            {post.content &&
              post.content.type === 'file' &&
              (() => {
                const fileOrigName = post.content.orig_name;
                return (
                  <div className="UserContent-File">
                    <HandleFileIcon fileName={fileOrigName} />
                    <div className="FileInfo">
                      <div className="FileName">{fileOrigName}</div>
                      <div className="FileSize">
                        <HandleFileSize bytes={post.content.size} />
                      </div>
                      <a
                        href={`data:application/octet-stream;base64,${post.content.file}`}
                        download={fileOrigName}
                      >
                        Скачать
                      </a>
                    </div>
                  </div>
                );
              })()}
            <div className="Interaction">
              <div className="InteractionCount">
                <I_Like />
                <div className="Likes">{post.likes_count}</div>
              </div>
              <div className="InteractionCount">
                <I_Dislike />
                <div className="Dislikes">{post.dislikes_count}</div>
              </div>
              <div className="InteractionCount">
                <I_Comment />
                <div className="Comments">{post.comments_count}</div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

const ViewEPACK = () => {
  const epackInputRef = useRef(null);
  const [epackVersion, setEpackVersion] = useState('');
  const [post, setPost] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const epackInput = epackInputRef.current;

    const handleFileChange = (event) => {
      const input = event.target.files[0];
      if (input) {
        const fileFormat = input?.name.split('.').pop();
        if (input && fileFormat === 'epack') {
          const reader = new FileReader();
          reader.onload = (e) => {
            const fileContent = e.target.result;
            HandleEPACK(JSON.parse(fileContent));
          };
          reader.readAsText(input);
        } else {
          Modal({
            Title: 'Ошибка',
            Content: 'Файл должен быть формата «epack»',
          });
        }
      }
    };

    if (epackInput) {
      epackInput.addEventListener('change', handleFileChange);
    }

    return () => {
      if (epackInput) {
        epackInput.removeEventListener('change', handleFileChange);
      }
    };
  }, []);

  const HandleEPACK = (postData) => {
    try {
      setEpackVersion(postData.E_VER);
      setPost(postData);
      setComments(postData?.Comments || postData?.comments || []);
    } catch (e) {
      Modal({
        Type: 'Error',
        Content: 'Ой, что-то пошло не так...',
      });
    }
  }

  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="EPACK-Page UI-PAGE_BODY">
          <div className="UI-C_L">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Загрузка файла</div>
                <div className="EPACK-FileInput">
                  <input ref={epackInputRef} type="file" id="EPACK_Input" />
                  <label htmlFor="EPACK_Input">Выбрать файл</label>
                  <div className="Text">он должен быть в формате ".epack"</div>
                </div>
              </div>
              <div className="UI-Block UI-InfoBlock">
                <I_INFO />
                <div>
                  EPACK - файл, который содержит в себе полный пост, а именно: аватарку, имя автора, текст поста, контент поста и тд. Этот файл не зависим от серверов Element'a, то есть если даже автор удалит пост, если вы сохранили EPACK у вас будет к нему доступ постоянно.
                </div>
              </div>
              <div>
                {post ? (
                  <HandlePost post={post} />
                ) : (
                  <div className="UI-ErrorMessage">Файл не выбран</div>
                )}
                {comments.length > 0 && (
                  <>
                    <div className="UI-PartitionName">Комментарии</div>
                    {comments.map((comment, index) => (
                      <HandleEPACKComment
                        key={index}
                        eVer={epackVersion}
                        comment={comment}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Gold пользователи</div>
                <div className="GoldSub-Users">
                  <GoldUsers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewEPACK;
