import { useEffect, useState } from 'react';
import axios from 'axios';
import { OLD_API_DOMAIN, useHeaders } from '../../../System/Elements/AccountManager';
import { PreloadGoldUsers } from '../../../System/UI/Preload';
import { HandleGoldUser } from '../../../System/Elements/Handlers';

const HandleCode = ({ code }) => {
    return (
        <div className={`Dashboard-SUB_KEY ${code.activated === true ? 'Dashboard-SK_A' : 'Dashboard-SK_NA'}`}>{code.key}</div>
    )
}

const Gold = () => {
    const headers = useHeaders();
    const [statistic, setStatistic] = useState(0);
    const [goldUsersLoaded, setGoldUsersLoaded] = useState(false);
    const [goldUsers, setGoldUsers] = useState([]);
    const [codes, setCodes] = useState([]);

    const loadGoldUsers = () => {
        axios.get(`${OLD_API_DOMAIN}/LoadGoldList.php`, {
            headers: headers,
        }).then((res) => {
            if (res.data.length > 0) {
                setGoldUsers(res.data.sort((a, b) => b.Subscribers - a.Subscribers));
            }
            setGoldUsersLoaded(true);
        });
    }

    const loadCodes = () => {
        axios.get(`${OLD_API_DOMAIN}/Dashboard.php?F=LOAD_GOLD_CODES`, {
            headers: headers,
        }).then((res) => {
            setCodes(res.data);
        });
    }

    useEffect(() => {
        axios.get(`${OLD_API_DOMAIN}/Dashboard.php?F=LOAD_GOLD_STATISTIC`, {
            headers: headers,
        }).then((res) => {
            setStatistic(res.data);
        });
        loadGoldUsers();
        loadCodes();
    }, [])

    const generateCode = () => {
        axios.get(`${OLD_API_DOMAIN}/Dashboard.php?F=GENERATE_CODE`, {
            headers: headers,
        }).then(() => {
            loadCodes();
        });
    }

    const recountGold = () => {
        axios.get(`${OLD_API_DOMAIN}/Dashboard.php?F=GOLD_RECOUNT`, {
            headers: headers,
        }).then(() => {
            loadGoldUsers();
            setGoldUsersLoaded(false);
        });
    }

    return (
        <>
            <div className="Dashboard-Blocks UI-B_FIRST">
                <div className="Dashboard-Block UI-Block">
                    <div className="Dashboard-B_Text">Пользователей с активной подпиской</div>
                    <div className="Dashboard-B_Count">
                        {statistic.active_subscribers_count}
                    </div>
                </div>
                <div className="Dashboard-Block UI-Block">
                    <div className="Dashboard-B_Text">Подписок активировано</div>
                    <div className="Dashboard-B_Count">
                        {statistic.activations_count}
                    </div>
                </div>
                <div className="Dashboard-Block UI-Block">
                    <div className="Dashboard-B_Text">Сгенерировано кодов</div>
                    <div className="Dashboard-B_Count">
                        {statistic.keys_count}
                    </div>
                </div>
                <div className="Dashboard-Block UI-Block">
                    <div className="Dashboard-B_Text">Не активированных кодов</div>
                    <div className="Dashboard-B_Count">
                        {statistic.non_activated_keys_count}
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex' }} className="UI-Block">
                <div className="Dashboard-BL_CNT">
                    <div className="UI-Title">Ключи для активации</div>
                    <button onClick={generateCode} className="Dashboard-SUB_BTN">Генерировать ключ</button>
                    <div className="Dashboard-SUB_LIST">
                        {
                            codes.length > 0 && (
                                codes.map((code) => (
                                    <HandleCode key={code.id} code={code} />
                                ))
                            )
                        }
                    </div>
                </div>
                <div className="Dashboard-BL_CNT">
                    <div className="UI-Title">Пользователи с подпиской</div>
                    <button
                        onClick={recountGold}
                        className="Dashboard-SUB_BTN"
                    >
                        Пересчитать
                    </button>
                    <div className="Dashboard-SUB_LIST">
                        {
                            goldUsersLoaded ? (
                                goldUsers.length > 0 && (
                                    goldUsers.map((user, i) => (
                                        <HandleGoldUser key={i} user={user} />
                                    ))
                                )
                            ) : (
                                <PreloadGoldUsers />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gold;