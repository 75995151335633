import React from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { LeftNavButton, TopBar } from '../../System/Elements/Navigate';
import { I_API, I_INFO, I_UPDATE, I_Back } from '../../System/UI/IconPack';
import Advantages from './Pages/Advantages';
import Rules from './Pages/Rules';
import Updates from './Pages/Updates';
import API from './Pages/API';

const Info = () => {
  const location = useLocation();
  const currentPage = location.pathname.split('/').slice(-1)[0];

  const pages = [
    {
      path: 'advantages',
      name: 'Преимущества',
      icon: <I_INFO />,
      element: <Advantages />
    },
    {
      path: 'rules',
      name: 'Правила',
      icon: <I_INFO />,
      element: <Rules />
    },
    {
      path: 'updates',
      name: 'Обновления',
      icon: <I_UPDATE />,
      element: <Updates />
    },
    {
      path: 'api',
      name: 'API',
      icon: <I_API />,
      element: <API />
    },
    {
      path: '/',
      icon: <I_Back />,
      element: <Navigate to="/" />,
      hidden: true
    },
  ];
  const routing = useRoutes(pages);

  return (
    <>
      <TopBar search={false} title={true} titleText={'Информация'} />
      <div className="Content">
        <div className="UI-L_NAV UI-B_FIRST">
          {
            pages.map((page, i) => {
              return !page?.hidden && (
                <LeftNavButton key={i} currentPage={currentPage} target={page.path}>
                  <div className="UI-LN_ICON">
                    {page.icon}
                  </div>
                  {page.name}
                </LeftNavButton>
              );
            })
          }
        </div>
        <div className="UI-PAGE_BODY">
          <div className="UI-ScrollView">
            {routing}
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;