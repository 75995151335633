import { useRef } from "react";
import { changePassword } from "../AccountManager";

export const ChangePassword = ({ setOpenModals, setModalData }) => {
  const oldPassword = useRef(null);
  const newPassword = useRef(null);
  return (
    <>
      <img
        src="/System/Images/All/Settings_ChangePass.png"
        className="UI-PB_Image"
        alt="фыр"
      />
      <div className="UI-PB_InputText">
        Запомните или запишите пароль, если вы его забудете, вы не сможете войти
        в аккаунт.
      </div>
      <input
        id="S-CP_OldPass"
        placeholder="Старый пароль"
        type="text"
        autoComplete="off"
        ref={oldPassword}
      />
      <input
        id="S-CP_NewPass"
        placeholder="Новый пароль"
        type="text"
        autoComplete="off"
        ref={newPassword}
      />
      <button
        className="UI-PB_NextButton"
        onClick={() => {
          changePassword({
            oldPassword: oldPassword.current.value,
            newPassword: newPassword.current.value,
          }).then((data) => {
            if (data?.Type) {
              oldPassword.current.value = "";
              newPassword.current.value = "";
              setOpenModals(true);
              setModalData({
                Title:
                  data.Type === "Error"
                    ? "Ошибка"
                    : data.Type === "Verify"
                    ? "Успешно"
                    : "Неизвестная ошибка",
                Message: data.Content,
              });
            }
          });
        }}
      >
        Сменить
      </button>
    </>
  );
};
