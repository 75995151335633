import { useEffect, useState } from 'react';
import { OLD_API_DOMAIN, useHeaders } from '../../../System/Elements/AccountManager';
import { I_Back, I_CLOSE, I_GOVERN, I_Send } from '../../../System/UI/IconPack';
import { HandleAvatar } from '../../../System/Elements/Handlers';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { useModal } from '../../../System/Context/Modal';

const HandleUser = ({ user }) => {
    const { openModal } = useModal();
    const headers = useHeaders();
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [password, setPassword] = useState('');

    const changePassword = () => {
        const data = {
            username: user.username,
            password: password
        }

        axios.post(`${OLD_API_DOMAIN}/Dashboard.php?F=CHANGE_USER_PASSWORD`, data, { headers: headers}).then((res) => {
            if (res.data.Type === 'Verify') {
                openModal({
                    'type': 'info',
                    'title': 'Успешно',
                    'text': 'Пароль изменен успешно',
                })
            } else {
                openModal({
                    'type': 'info',
                    'title': 'Ошибка',
                    'text': res.data.Content,
                })
            }
        })
        setChangePasswordOpen(false);
        setPassword('');
    }

    return (
        <div className="Dashboard-User UI-Block">
            <div className="Avatar">
                <HandleAvatar avatar={user.avatar} name={user.name} />
            </div>
            <div className="BaseInfo">
                <div className="Name">{user.name}</div>
                <div className="Username">@{user.username}</div>
            </div>
            <div className="LiteInfo">
                <div className="Text">UID: {user.id}</div>
                <div className="Text">{user.email}</div>
            </div>
            <AnimatePresence>
                {
                    changePasswordOpen && (
                        <motion.div
                            className="ChangePassword"
                            initial={{ opacity: 0, x: 30 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 30 }}
                            transition={{ duration: 0.1 }}
                        >
                            <input value={password} onChange={(e) => { setPassword(e.target.value) }} type="text" placeholder="Новый пароль" />
                            <button className="Send" onClick={changePassword}>
                                <I_Send />
                            </button>
                            <button className="Close" onClick={() => setChangePasswordOpen(false)}>
                                <I_CLOSE />
                            </button>
                        </motion.div>
                    )
                }
            </AnimatePresence>
            <div className="GovernButtons">
                <button onClick={() => { setChangePasswordOpen(true) }}>
                    <I_GOVERN />
                </button>
            </div>
        </div>
    )
}

const Users = () => {
    const headers = useHeaders();
    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);

    const loadUsers = ({ startIndex, searchValue }) => {
        const data = {
            start_index: startIndex,
            search_value: searchValue
        }

        axios.post(`${OLD_API_DOMAIN}/Dashboard.php?F=LOAD_USERS`, data, { headers: headers }).then((res) => {
            if (res.data) {
                setUsers(res.data.users);
                setUsersCount(res.data.users_count);
            }
        })
    }

    const search = () => {
        loadUsers({ startIndex: 0, searchValue: searchValue });
        setStartIndex(0);
    }

    const next = () => {
        if (startIndex < usersCount) {
            setStartIndex(startIndex + 50);
            loadUsers({ startIndex: startIndex + 50, searchValue: searchValue });
        }
    }

    const back = () => {
        if (startIndex !== 0) {
            setStartIndex(startIndex - 50);
            loadUsers({ startIndex: startIndex - 50, searchValue: searchValue });
        }
    }

    useEffect(() => {
        loadUsers({ startIndex: 0, searchValue: '' });
    }, [])

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Пользователи</div>
                <div className="Dashboard-Search">
                    <input value={searchValue} onChange={(e) => { setSearchValue(e.target.value) }} type="text" placeholder="Поиск" />
                    <button onClick={search}>
                        <I_Send />
                    </button>
                </div>
            </div>
            <div className="Dashboard-Users">
                {
                    users.length > 0 && (
                        users.map((user) => (
                            <HandleUser key={user.id} user={user} />
                        ))
                    )
                }
            </div>
            <div className="Dashbaard-BottomBar">
                <button onClick={back} className={`Back ${startIndex === 0 ? 'NonActiveButton' : ''}`}>
                    <I_Back />
                    Назад
                </button>
                <div className="Pages">
                    <div style={{ marginRight: 3 }}>{startIndex + 50}</div>
                    из
                    <div style={{ marginLeft: 3 }}>{usersCount}</div>
                </div>
                <button onClick={next} className={`Next ${startIndex > usersCount ? 'NonActiveButton' : ''}`}>
                    <I_Back />
                    Вперёд
                </button>
            </div>
        </>
    )
}

export default Users;