import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Animate } from '../System/Elements/Function';
import { OLD_API_DOMAIN, Headers, API_DOMAIN } from '../System/Elements/AccountManager';
import { HandleAvatar } from '../System/Elements/Handlers';
import { I_Logo } from '../System/UI/IconPack';
import { PreloadLastUsers } from '../System/UI/Preload';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useModal } from '../System/Context/Modal';

export const Authorization = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();

  // Капча
  const hcaptchaRef = useRef(null);
  const [token, setToken] = useState(null);

  // Данные для входа
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  // Данные для регистрации
  const [regName, setRegName] = useState('');
  const [regUsername, setRegUsername] = useState('');
  const [regEmail, setRegEmail] = useState('');
  const [regPassword, setRegPassword] = useState('');
  const [regAccept, setRegAccept] = useState(false);

  // Подтверждение почты
  const [code, setCode] = useState('');

  // Восстановление пароля (шаг 1)
  const [rEmail, setREmail] = useState('');

  // Восстановление пароля (шаг 2)
  const [rCode, setRCode] = useState('');

  // Восстановление пароля (шаг 3)
  const [rPassword, setRPassword] = useState('');

  // Переменные
  const [version, setVersion] = useState(null);
  const [lastUsers, setLastUsers] = useState([]);

  // Загрузка данных
  useEffect(() => {
    const fetchData = async () => {
      axios.get(OLD_API_DOMAIN + 'LoadElementData.php').then((response) => {
        setVersion(response.data.last_update);
        setLastUsers(response.data.users);
      })
    };
    fetchData();
  }, []);

  // Обработчики событий
  const login = (e) => {
    e.preventDefault();

    const data = {
      email: loginEmail,
      password: loginPassword,
      device_type: 'browser',
      device: 'Element Web 2.2'
    };

    axios.post(`${API_DOMAIN}/auth/login`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.status === 'success') {
        localStorage.setItem('S_KEY', answer.S_KEY);
        navigate('/');
      } else if (answer.status === 'error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.text,
        })
      }
    })
  }
  const registration = () => {
    const data = {
      name: regName,
      username: regUsername,
      email: regEmail,
      password: regPassword,
      accept: regAccept,
      h_captcha: token
    };
    axios.post(`${API_DOMAIN}/auth/register`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.status === 'success') {
        if (answer.email_verify === true) {
          Animate('.Reg', 'AUTH-HIDE_LOGIN', 0.3);
          Animate('.Verify', 'AUTH-SHOW_REG', 0.3);
        } else {
          localStorage.setItem('S_KEY', answer.S_KEY);
          navigate('/');
        }
      } else if (answer.status === 'error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.text,
        })
      }
    })
  }
  const checkCode = () => {
    const data = {
      Code: code
    };
    axios.post(`${OLD_API_DOMAIN}/Authorization.php?F=REG_V_M`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        localStorage.setItem('S_KEY', answer.Content);
        navigate('/');
      } else if (answer.Type === 'Error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.Content,
        })
      }
    })
  }
  const restoreStep1 = () => {
    const data = {
      Step: 1,
      Email: rEmail,
      HCaptcha: token
    };
    axios.post(`${OLD_API_DOMAIN}Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        Animate('#AUTH-R_FORM_1', 'AUTH-HIDE_LOGIN', 0.3);
        Animate('#AUTH-R_FORM_2', 'AUTH-SHOW_REG', 0.3);
      } else if (answer.Type === 'Error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.Content,
        })
      }
    })
  }
  const restoreStep2 = () => {
    const data = {
      Step: 2,
      Code: rCode
    };
    axios.post(`${OLD_API_DOMAIN}/Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        localStorage.setItem('S_KEY', answer.Content);
        Animate('#AUTH-R_FORM_2', 'AUTH-HIDE_LOGIN', 0.3);
        Animate('#AUTH-R_FORM_3', 'AUTH-SHOW_REG', 0.3);
      } else if (answer.Type === 'Error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.Content,
        })
      }
    })
  }
  const restoreStep3 = () => {
    const data = {
      Step: 3,
      S_KEY: localStorage.getItem('S_KEY'),
      Password: rPassword
    };
    axios.post(`${OLD_API_DOMAIN}/Authorization.php?F=ACCOUNT_RECOVER`, data, { headers: Headers }).then((res) => {
      const answer = res.data;
      if (answer.Type === 'Verify') {
        navigate('/');
      } else if (answer.Type === 'Error') {
        openModal({
          type: 'info',
          title: 'Ошибка',
          text: answer.Content,
        })
      }
    })
  }

  // Переключатель форм
  const goToReg = () => {
    Animate('.Login', 'AUTH-HIDE_LOGIN', 0.3);
    Animate('.Reg', 'AUTH-SHOW_REG', 0.3);
  }
  const goToLogin = () => {
    Animate('.Login', 'AUTH-SHOW_LOGIN', 0.3);
    Animate('.Reg', 'AUTH-HIDE_REG', 0.3);
  }
  const goToRestore = () => {
    Animate('.Login', 'AUTH-HIDE_LOGIN', 0.3);
    Animate('#AUTH-R_FORM_1', 'AUTH-SHOW_REG', 0.3);
  }
  const goToBack = (e) => {
    let thisElement = e.currentTarget;
    let target = thisElement.closest('.Auth-Container').getAttribute('target');
    let thisID = thisElement.closest('.Auth-Container').getAttribute('id');
    Animate('#' + target, 'AUTH-SHOW_LOGIN', 0.3);
    Animate('#' + thisID, 'AUTH-HIDE_REG', 0.3);
  }

  return (
    <div className="Content">
      <div className="Auth-Body UI-Block">
        <div className="Left">
          <div className="LogoAndTitle">
            <I_Logo className="Logo" />
            <div className="Title"><span>Element</span> - {t('welcome_text')}</div>
            <div className="LastUsers">
              {
                lastUsers.length && lastUsers.length > 0 ? (
                  lastUsers.map((user, i) => (
                    <NavLink key={i} to={`/e/${user.Username}`} className="User">
                      <div className="Avatar">
                        <HandleAvatar avatar={user.Avatar} name={user.Name} />
                      </div>
                      <div className="Name">
                        {user.Name}
                      </div>
                    </NavLink>
                  ))
                ) : (
                  <PreloadLastUsers />
                )
              }
            </div>
          </div>
          <div className="Watermark">
            {
              version ? (
                `${t('author_text')} ${version}`
              ) : (
                <div className="UI-PRELOAD" style={{ width: '150px', height: '12px' }}></div>
              )
            }
            <div className="Part">связь - <a style={{ cursor: 'pointer', userSelect: 'all' }}>elemsupport@proton.me</a></div>
          </div>
        </div>
        <div className="Right">
          {/* Вход */}
          <form id="AUTH-LOGIN" className="Login" onSubmit={login}>
            <div className="Form_Container-Text">{t('login_title')}</div>
            <div className="Authorization-Form">
              <input name="email" value={loginEmail} onChange={(e) => { setLoginEmail(e.target.value) }} type="email" autoComplete="email" placeholder={t('input_email')} />
              <input name="password" value={loginPassword} onChange={(e) => { setLoginPassword(e.target.value) }} type="password" autoComplete="current-password" placeholder={t('input_password')} />
              <button type="submit" className="Authorization-BTN_1">{t('login_button')}</button>
            </div>
            <button type="button" onClick={goToReg} className="Authorization-BTN_2" id="GO_REG">{t('create_account')}</button>
            <button type="button" onClick={goToRestore} className="Authorization-BTN_2" id="GO_RESTORE" style={{ marginTop: '5px' }}>{t('recover_account')}</button>
          </form>
          {/* Регистрация */}
          <div className="Reg">
            <div className="Form_Container-Text">{t('create_account_title')}</div>
            <div className="Authorization-Form">
              <input value={regName} onChange={(e) => { setRegName(e.target.value) }} type="text" placeholder={t('input_name')} />
              <input value={regUsername} onChange={(e) => [setRegUsername(e.target.value)]} type="text" placeholder={t('input_username')} />
              <input value={regEmail} onChange={(e) => { setRegEmail(e.target.value) }} type="text" placeholder={t('input_email')} />
              <input value={regPassword} onChange={(e) => { setRegPassword(e.target.value) }} type="text" placeholder={t('input_password')} />
              <HCaptcha
                sitekey="29c6b1c2-7e78-43ec-8bf8-5de49c58c54a"
                ref={hcaptchaRef}
                onVerify={setToken}
              />
              <div className="Authorization-Accept_R">
                <input onChange={() => { setRegAccept(!regAccept) }} id="RF-Accept" type="checkbox" style={{ display: 'none' }} />
                <label htmlFor="RF-Accept" className={`UI-Switch ${regAccept ? 'UI-Switch-On' : ''}`}></label>
                <div style={{ marginLeft: '10px' }}>{t('accept_rules_p1')} <NavLink to="/info/rules" className="Authorization-Accept_R_BTN">{t('accept_rules_p2')}</NavLink></div>
              </div>
              <button onClick={registration} className="Authorization-BTN_1">{t('create_account_button')}</button>
            </div>
            <button onClick={goToLogin} className="Authorization-BTN_2">{t('login_button_go_to')}</button>
          </div>
          {/* Проверка почты */}
          <div className="Verify">
            <div className="Form_Container-Text">Проверка почты</div>
            <div className="Authorization-Form">
              <input style={{ marginBottom: 5 }} value={code} onChange={(e) => { setCode(e.target.value) }} type="text" placeholder="Код из письма" />
              <div style={{ margin: 0 }} className="UI-PartitionName">
                ВНИМАНИЕ! Могут быть проблемы с доставкой кода на Gmail, рекомендуется использовать почту Proton.
              </div>
              <button onClick={checkCode} className="Authorization-BTN_1">Проверить</button>
            </div>
          </div>
          {/* Восстановление доступа */}
          <div id="AUTH-R_FORM_1" target="AUTH-LOGIN" className="Auth-Container">
            <div className="Form_Container-Text">Введите почту</div>
            <div className="Authorization-Form">
              <input value={rEmail} onChange={(e) => { setREmail(e.target.value) }} type="text" placeholder="Почта" />
              <HCaptcha
                sitekey="29c6b1c2-7e78-43ec-8bf8-5de49c58c54a"
                ref={hcaptchaRef}
                onVerify={setToken}
              />
              <button onClick={restoreStep1} className="Authorization-BTN_1">Далее</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
          <div id="AUTH-R_FORM_2" target="AUTH-R_FORM_1" className="Auth-Container">
            <div className="Form_Container-Text">Введите код</div>
            <div className="Authorization-Form">
              <input value={rCode} onChange={(e) => { setRCode(e.target.value) }} className="Authorization-Input" type="text" placeholder="Код" />
              <button onClick={restoreStep2} className="Authorization-BTN_1">Проверить</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
          <div id="AUTH-R_FORM_3" target="AUTH-R_FORM_2" className="Auth-Container">
            <div className="Form_Container-Text">Введите новый пароль</div>
            <div className="Authorization-Form">
              <input value={rPassword} onChange={(e) => { setRPassword(e.target.value) }} className="Authorization-Input" type="text" placeholder="Пароль" />
              <button onClick={restoreStep3} className="Authorization-BTN_1">Завершить</button>
            </div>
            <button onClick={goToBack} className="Authorization-BTN_2">Назад</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Authorization;