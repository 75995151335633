import LottieAnimation from '../../../System/Modules/LotteAnimation';
import L_Fire from '../../../System/UI/Lottie/Fire.json';
import L_Zipper from '../../../System/UI/Lottie/Zipper.json';
import L_Comp_1 from '../../../System/UI/Lottie/Comp 1.json';
import L_Key from '../../../System/UI/Lottie/Key.json';
import L_HEART_PURPLE from '../../../System/UI/Lottie/HEART PURPLE.json';

const Advantages = () => {
    return (
        <div className="UI-Block Info-Block UI-B_FIRST">
            <div className="UI-Title">Да почему именно Element?!</div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--PUSTOTA_WIDTH)' }}>
                <div className="Info-A_Block">
                    <div className="BlockContent">
                        <div className="Title">Современный и приятый интерфейс</div>
                        <div className="Description">
                            Создавая интерфейс Элемента, была цель создать: простой, понятный, современный интерфейс, и это получилось! Ну, наверное...
                        </div>
                    </div>
                    <LottieAnimation className="Icon" lottie={L_Fire} />
                </div>
                <div className="Info-A_Block">
                    <div className="BlockContent">
                        <div className="Title">Говори чё хочешь</div>
                        <div className="Description">
                            Полная свобода слова!
                        </div>
                    </div>
                    <LottieAnimation className="Icon" lottie={L_Zipper} />
                </div>
                <div className="Info-A_Block">
                    <div className="BlockContent">
                        <div className="Title">А вы кто?</div>
                        <div className="Description">
                            Никакого сбора данных, таргетинговой рекламы. Всё что нам нужно - ваша почта, ну не обязательно ваша
                        </div>
                    </div>
                    <LottieAnimation className="Icon" lottie={L_Comp_1} />
                </div>
                <div className="Info-A_Block">
                    <div className="BlockContent">
                        <div className="Title">Лучше чем ВК</div>
                        <div className="Description">
                            В мессенджере используется RSA + AES шифрование, с использованием ВАШЕГО ключа
                        </div>
                    </div>
                    <LottieAnimation className="Icon" lottie={L_Key} />
                </div>
                <div className="Info-A_Block">
                    <div className="BlockContent">
                        <div className="Title">Открытость</div>
                        <div className="Description">
                            Мы рады поделиться исходным кодом Элемента!
                        </div>
                    </div>
                    <LottieAnimation className="Icon" lottie={L_HEART_PURPLE} />
                </div>
            </div>
        </div>
    );
};

export default Advantages;