import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { LeftNavButton, TopBar } from '../../System/Elements/Navigate';
import { I_Back, I_GoldStar, I_Panel, I_Users } from '../../System/UI/IconPack';
import Statistic from './Pages/Statistic';
import Users from './Pages/Users';
import Gold from './Pages/Gold';

const Panel = () => {
    const location = useLocation();
    const currentPage = location.pathname.split('/').slice(-1)[0];
    
    const pages = [
        {
            path: 'stat',
            name: 'Статистика',
            icon: <I_Panel />,
            element: <Statistic />
        },
        {
            path: 'users',
            name: 'Пользователи',
            icon: <I_Users />,
            element: <Users />
        },
        {
            path: 'gold',
            name: 'Подписка',
            icon: <I_GoldStar />,
            element: <Gold />
        },
        {
            path: '/',
            name: 'Выход',
            icon: <I_Back />,
            element: <Navigate to="/" />
        },
    ];
    const routing = useRoutes(pages);

    return (
        <>
            <TopBar title={true} titleText="Панель управления" />
            <div className="Content">
                <div className="UI-L_NAV UI-B_FIRST">
                    {
                        pages.map((page, i) => (
                            <LeftNavButton key={i} currentPage={currentPage} target={page.path}>
                                <div className="UI-LN_ICON">
                                    {page.icon}
                                </div>
                                {page.name}
                            </LeftNavButton>
                        ))
                    }
                </div>
                <div className="UI-PAGE_BODY">
                    <div className="UI-ScrollView">
                        {routing}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Panel;