import { useState } from 'react';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { Animate } from '../System/Elements/Function';
import { Modal, QueryWindow } from '../System/Elements/Modal';
import GoldUsers from '../System/Elements/GoldUsers';

const Channels = () => {
    return (
        <>
            <TopBar search={true} />
            <div className="Content">
                <LeftBar />
                <div className="GoldSub-Page UI-PAGE_BODY">
                    <div className="UI-C_L">
                        <div className="UI-ScrollView">
                        </div>
                    </div>
                    <div className="UI-C_R">
                        <div className="UI-ScrollView">
                            <div className="UI-Block UI-B_FIRST">
                                <div className="UI-Title" style={{ width: '100%' }}>
                                    Gold пользователи
                                </div>
                                <div className="GoldSub-Users">
                                    <GoldUsers />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Channels;