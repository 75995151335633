import { useEffect, useState } from 'react';
import { OLD_API_DOMAIN, useHeaders } from './AccountManager';
import { PreloadGoldUsers } from '../UI/Preload';
import { HandleGoldUser } from './Handlers';
import axios from 'axios';

const GoldUsers = () => {
    const headers = useHeaders();
    const [goldUsers, setGoldUsers] = useState([]);

    useEffect(() => {
        axios.get(`${OLD_API_DOMAIN}LoadGoldList.php`, {
            headers: headers,
        }).then((res) => {
            if (res.data.length > 0) {
                setGoldUsers(res.data.sort((a, b) => b.Subscribers - a.Subscribers));
            }
        });
    }, [])

    return goldUsers.length > 0 ? (
        goldUsers.map((user, i) => <HandleGoldUser key={i} user={user} />)
    ) : (
        <PreloadGoldUsers />
    );
};

export default GoldUsers;
