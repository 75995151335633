import Lottie from 'lottie-react';

const LottieAnimation = ({ className, lottie }) => {
    const options = {
        animationData: lottie,
        loop: true,
        autoplay: true,
    };
    return (
        <div className={className}>
            <Lottie {...options} />
        </div>
    );
};

export default LottieAnimation;