import { useState, useEffect } from 'react';

const useDataBase = (dbName, version) => {
    const [db, setDb] = useState(null);
    const [error, setError] = useState(null);
    const [isDbReady, setIsDbReady] = useState(false);

    useEffect(() => {
        const openRequest = indexedDB.open(dbName, version);

        openRequest.onupgradeneeded = function(event) {
            const db = event.target.result;

            if (!db.objectStoreNames.contains('downloads')) {
                db.createObjectStore('downloads', { keyPath: 'mid' });
            }
            if (!db.objectStoreNames.contains('files')) {
                db.createObjectStore('files', { keyPath: 'mid' });
            }
        };

        openRequest.onsuccess = function(event) {
            setDb(event.target.result);
            setIsDbReady(true);
            console.log('База данных успешно открыта:', dbName);
        };

        openRequest.onerror = function(event) {
            setError(event.target.error);
            console.error('Ошибка при открытии базы данных:', event.target.error);
        };

        return () => {
            if (db) {
                db.close();
            }
        };
    }, [dbName, version]);

    // Функция для добавления данных
    const addData = async (storeName, data) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.add(data);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (error) => {
                console.error('Ошибка при добавлении данных:', error);
                reject(error);
            };
        });
    };

    const getData = async (storeName, key) => {
        if (!db) throw new Error('База данных не инициализирована');
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.get(key);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (error) => {
                console.error('Ошибка при получении данных:', error);
                reject(error);
            };
        });
    };

    const updateData = async (storeName, data) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.put(data);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (error) => {
                console.error('Ошибка при обновлении данных:', error);
                reject(error);
            };
        });
    };

    const deleteData = async (storeName, key) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.delete(key);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (error) => {
                console.error('Ошибка при удалении данных:', error);
                reject(error);
            };
        });
    };

    return { db, error, isDbReady, addData, getData, updateData, deleteData };
};

export default useDataBase;