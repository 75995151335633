import { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { PreloadPosts } from '../System/UI/Preload';
import { OLD_API_DOMAIN, useAccountData, useHeaders } from '../System/Elements/AccountManager';
import { I_Music, I_AddFile, I_CLOSE, I_Settings, I_Plus, I_UploadImage } from '../System/UI/IconPack';
import { HandlePost, HandleAvatar } from '../System/Elements/Handlers';
import { Animate, AnimateElement } from '../System/Elements/Function';
import { useInView } from 'react-intersection-observer';
import { UniversalPanel, Window } from '../System/Elements/Modal';
import { FormButton } from '../System/Modules/UIKit';
import axios from 'axios';
import GoldUsers from '../System/Elements/GoldUsers';
import { useTranslation } from 'react-i18next';
import { useModal } from '../System/Context/Modal';

const CreateChannel = () => {
  const headers = useHeaders();
  const [coverB64, setCoverB64] = useState(null);
  const [avatarB64, setAvatarB64] = useState(null);
  const [cover, setCover] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const imageToB64 = (image) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;

      if (image) {
        reader.readAsDataURL(image);
      }
    });
  };

  const handleCover = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const b64 = await imageToB64(file);
      setCoverB64(b64);
    }
  };
  const handleAvatar = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const b64 = await imageToB64(file);
      setAvatarB64(b64);
    }
  };

  const create = () => {
    let data = {};
    data.name = name;
    data.username = username;
    if (cover) {
      data.cover = cover;
    }
    if (avatar) {
      data.avatar = avatar;
    }
    if (description) {
      data.description = description;
    }

    axios.post(`${OLD_API_DOMAIN}/AddChannel.php`, data, { headers: headers })
      .then((res) => {
        console.log(res);
      });
  }

  return (
    <>
      <div className="Cover">
        <input
          id="CC-CoverInput"
          type="file"
          accept="image/*"
          onChange={(e) => {
            setCover(e.target.files[0]);
            handleCover(e);
          }}
        />
        <label htmlFor="CC-CoverInput"></label>
        {coverB64 ? <img src={coverB64} /> : <I_UploadImage />}
      </div>
      <div className="Avatar">
        <input
          id="CC-AvatarInput"
          type="file"
          accept="image/*"
          onChange={(e) => {
            setAvatar(e.target.files[0]);
            handleAvatar(e);
          }}
        />
        <label htmlFor="CC-AvatarInput"></label>
        {avatarB64 ? <img src={avatarB64} /> : <I_UploadImage />}
      </div>
      <div className="Inputs">
        <div className="InputContainer">
          @
          <input
            placeholder="уникальное_имя"
            value={username}
            autoComplete="off"
            type="text"
            maxLength="30"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <input
          placeholder="Введите название"
          value={name}
          autoComplete="off"
          type="text"
          maxLength="30"
          onChange={(e) => setName(e.target.value)}
        />
        <textarea
          placeholder="Введите описание"
          value={description}
          autoComplete="off"
          type="text"
          maxLength="100"
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <FormButton onClick={create}>Создать канал</FormButton>
    </>
  );
};

const AddPost = ({ loadPosts, postsCategory, setPosts, setPostsSI }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const accountData = useAccountData();
  const [selectedChannel, setSelectedChannel] = useState(() => {
    const channel = JSON.parse(localStorage.getItem('SelectedChannel'));
    return channel || false;
  });
  const headers = useHeaders();
  const fileRefs = useRef({});
  const postInputRef = useRef(null);
  const [postText, setPostText] = useState('');
  const [postFiles, setPostFiles] = useState([]);
  const [postFilesHidden, setPostFilesHidden] = useState(true);
  const [postFilesImages, setPostFilesImages] = useState(false);
  const [postSettingsOpen, setPostSettingsOpen] = useState(false);
  const [fsClearMetadata, setFsClearMetadata] = useState(false);
  const [fsCensoring, setFsCensoring] = useState(false);
  const [changeAccountOpen, setChangeAccountOpen] = useState(false);
  const [createChannelOpen, setCreateAccountOpen] = useState(false);

  const handleInputChange = () => {
    const input = postInputRef.current;
    if (input) {
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
    }
  };

  // Отправка поста
  const addPost = () => {
    const formData = new FormData();
    formData.append('Text', postText);
    if (postFiles.length > 0) {
      for (let i = 0; i < postFiles.length; i++) {
        formData.append('Files[]', postFiles[i]);
      }
      if (postFilesImages) {
        formData.append('ClearMetadataIMG', fsClearMetadata);
        formData.append('CensoringIMG', fsCensoring);
      }
    }
    if (selectedChannel) {
      formData.append('from', selectedChannel.Username);
    }
    axios
      .post(`${OLD_API_DOMAIN}/AddPost.php`, formData, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        if (data && data.Type === 'Verify') {
          postInputRef.current.value = '';
          postInputRef.current.removeAttribute('style');
          setPostFilesHidden(true);
          setPostSettingsOpen(false);
          setPostFilesImages(false);
          loadPosts({
            type: postsCategory,
            startIndex: 0
          }).then((posts) => {
            setPosts(posts);
            setPostsSI(0);
          });
          setPostText('');
        } else if (data.Type === 'Error') {
          openModal({
            type: 'info',
            title: 'Ошибка',
            text: data.Content
          });
        }
      });
  };

  const handleFilesInput = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    for (const file of filesArray) {
      const fileFormat = file.name.split('.').pop().toLowerCase();
      if (!['jpeg', 'jpg', 'png', 'gif'].includes(fileFormat)) {
        setPostFilesImages(false);
      } else {
        setPostFilesImages(true);
      }
    }
    setPostFiles(files);
    if (postFilesHidden) {
      setPostFilesHidden(false);
    }
  };

  const handleFileRemove = useCallback(
    async (i) => {
      const updatedFiles = Array.from(postFiles).filter((_, index) => index !== i);
      AnimateElement(fileRefs.current[i], 'FILE_INPUT-DELETE', 0.2);
      await new Promise((resolve) => setTimeout(resolve, 200));
      setPostFiles(updatedFiles);
      if (updatedFiles.length === 0) {
        if (postSettingsOpen) {
          Animate('#AP-FS_BUTTON', 'AP-FILE_SETTINGS-NOTACTIVE', 0.2);
          Animate('#AP-FILES_SETTINGS', 'ELEMENT-HIDE', 0.2);
          setPostSettingsOpen(false);
        }
        Animate('.UI-UniversalPanel', 'ELEMENT-HIDE', 0.2);
        setPostFilesImages(false);
        setPostFilesHidden(true);
      }
    },
    [postFiles]
  );

  const toggleFilesSettings = () => {
    setPostSettingsOpen(!postSettingsOpen);
  };
  const handleFsClearMetadata = () => {
    setFsClearMetadata(!fsClearMetadata);
  };
  const handleFsCensoring = () => {
    setFsCensoring(!fsCensoring);
  };

  const selectChannel = (channel) => {
    setSelectedChannel(channel);
    localStorage.setItem('SelectedChannel', JSON.stringify(channel));
  }

  return (
    <>
      <div className="UI-Block AddPost UI-B_FIRST">
        <div className="UI-Title">{t('add_post_title')}</div>
        <textarea
          value={postText}
          onChange={(e) => {
            setPostText(e.target.value);
            handleInputChange();
          }}
          placeholder={t('post_text_input')}
          maxLength="3400"
          ref={postInputRef}
        ></textarea>
        <div className="Buttons">
          <div style={{ gap: '5px', display: 'flex' }}>
            <button
              onClick={() => {
                setChangeAccountOpen(!changeAccountOpen);
              }}
              className="SelectAccount"
            >
              <div className="Avatar">
                {
                  !selectedChannel ? (
                    <HandleAvatar avatar={accountData.Avatar} name={accountData.Name} />
                  ) : (
                    <HandleAvatar avatar={selectedChannel.Avatar} name={selectedChannel.Name} />
                  )
                }
              </div>
            </button>
            <button onClick={addPost} className="Send">
              {t('add_post_button')}
            </button>
          </div>
          <input id="AP-FILE_INPUT" onChange={handleFilesInput} type="file" multiple></input>
          <div className="AddFileButtons">
            <button
              onClick={() => {
                navigate('music');
              }}
            >
              <I_Music />
            </button>
            <label htmlFor="AP-FILE_INPUT">
              <I_AddFile />
            </label>
            {postFilesImages && (
              <button onClick={toggleFilesSettings} id="AP-FS_BUTTON">
                <I_Settings />
              </button>
            )}
          </div>
        </div>
        {/* Панели действий */}

        {/* Список файлов */}
        <UniversalPanel isOpen={postFiles.length > 0}>
          {Array.from(postFiles).map((file, i) => (
            <div
              key={file.name}
              ref={(el) => {
                fileRefs.current[i] = el;
              }}
              className="Item"
            >
              <div className="Name">{file.name}</div>
              <button className="Close" onClick={() => handleFileRemove(i)}>
                <I_CLOSE />
              </button>
            </div>
          ))}
        </UniversalPanel>
        {/* Настройка файлов */}
        <UniversalPanel isOpen={postSettingsOpen}>
          <div className="Item">
            <input id="AP-CI" type="checkbox" style={{ display: 'none' }} />
            Очистить метаданные
            <label
              onClick={handleFsClearMetadata}
              htmlFor="AP-CI"
              className={`UI-Switch ${fsClearMetadata ? 'UI-Switch-On' : ''}`}
            ></label>
          </div>
          <div className="Item">
            <input id="AP-CMI" type="checkbox" style={{ display: 'none' }} />
            Деликатный контент
            <label
              onClick={handleFsCensoring}
              htmlFor="AP-CMI"
              className={`UI-Switch ${fsCensoring ? 'UI-Switch-On' : ''}`}
            ></label>
          </div>
        </UniversalPanel>
        {/* Выбор аккаунта */}
        <UniversalPanel className="AddPost-SelectFrom" isOpen={changeAccountOpen}>
          <>
            <div className="Title">Написать от имени...</div>
            <div className="Accounts">
              <button onClick={() => { selectChannel(false) }} className="Account">
                <div className="Avatar">
                  <HandleAvatar avatar={accountData.Avatar} name={accountData.Name} />
                </div>
                {accountData.Name}
              </button>
              {
                accountData?.channels?.length > 0 && (
                  accountData.channels.map((channel, i) => (
                    <button key={i} onClick={() => { selectChannel(channel) }} className="Account">
                      <div className="Avatar">
                        <HandleAvatar avatar={channel.Avatar} name={channel.Name} />
                      </div>
                      {channel.Name}
                    </button>
                  ))
                )
              }
              <button
                onClick={() => {
                  setCreateAccountOpen(true);
                }}
                className="Account"
              >
                <div style={{ background: 'rgb(255 255 255 / 0%)' }} className="Avatar">
                  <I_Plus style={{ fill: 'var(--TEXT_COLOR)' }} />
                </div>
                Создать канал
              </button>
            </div>
          </>
        </UniversalPanel>
      </div>
      <Window
        title="Создать канал"
        content={<CreateChannel />}
        contentClass="ChannelForm"
        style={{ width: 'fit-content' }}
        isOpen={createChannelOpen}
        setOpen={setCreateAccountOpen}
      />
    </>
  );
};

const Home = () => {
  const { t } = useTranslation();
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const postsRef = useRef(null);
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [posts, setPosts] = useState([]);
  const [postsCategory, setPostsCategory] = useState(
    localStorage.getItem('S-PostsType') ? localStorage.getItem('S-PostsType') : 'LATEST'
  );
  const [postsSI, setPostsSI] = useState(25);
  const [morePostsLoading, setMorePostsLoading] = useState(false);

  useEffect(() => {
    loadPosts({ type: postsCategory, startIndex: 0 }).then((posts) => {
      if (posts.length > 0) {
        setPosts(posts);
        setPostsLoaded(true);
      }
    });
  }, []);

  const handleDeletePost = (data, id) => {
    if (data.Type == 'Verify') {
      const updatedPosts = posts.filter((post) => post.ID !== id);
      setPosts(updatedPosts);
    }
  };

  // Загрузка постов
  const loadPosts = async ({ type, startIndex }) => {
    try {
      const res = await axios.post(
        `${OLD_API_DOMAIN}/LoadPosts.php?F=${type}`,
        { StartIndex: startIndex },
        {
          headers: Headers,
        }
      );

      if (Array.isArray(res.data) && res.data.length > 0) {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error loading posts:', error);
      return [];
    }
  };

  const selectPostsCategory = (category) => {
    setPostsCategory(category);
    setPostsLoaded(false);
    loadPosts({ type: category }).then((posts) => {
      setPosts(posts);
      setPostsLoaded(true);
      setPostsSI(0);
    });
  };

  const { ref: postsEndRef, inView: postsEndIsView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (postsEndIsView) {
      setMorePostsLoading(true);
      setPostsSI(postsSI + 25);
      loadPosts({ type: postsCategory, startIndex: postsSI }).then((posts) => {
        if (Array.isArray(posts) && posts.length > 0) {
          setPosts((prevPosts) => [...prevPosts, ...posts]);
          setPostsSI((prevPostsSI) => prevPostsSI + 25);
          setMorePostsLoading(false);
        }
      });
    }
  }, [postsEndIsView]);

  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="HomePage UI-PAGE_BODY">
          <div className="UI-C_L">
            <div ref={postsRef} className="UI-ScrollView">
              <AddPost
                loadPosts={loadPosts}
                postsCategory={postsCategory}
                setPosts={setPosts}
                setPostsSI={setPostsSI}
              />
              <div className="UI-Tabs">
                <button
                  onClick={() => {
                    selectPostsCategory('LATEST');
                  }}
                  className={`Tab${postsCategory === 'LATEST' ? ' ActiveTab' : ''}`}
                >
                  {t('category_last')}
                </button>
                <button
                  onClick={() => {
                    selectPostsCategory('REC');
                  }}
                  className={`Tab${postsCategory === 'REC' ? ' ActiveTab' : ''}`}
                >
                  {t('category_recommended')}
                </button>
                <button
                  onClick={() => {
                    selectPostsCategory('SUBSCRIPTIONS');
                  }}
                  className={`Tab${postsCategory === 'SUBSCRIPTIONS' ? ' ActiveTab' : ''}`}
                >
                  {t('category_subscriptions')}
                </button>
              </div>
              <div className="Posts">
                {postsLoaded ? (
                  posts.length > 0 ? (
                    posts.map((post) => (
                      <HandlePost key={`PID-${post.ID}`} post={post} onDelete={handleDeletePost} />
                    ))
                  ) : (
                    <div className="UI-ErrorMessage">Ой, а тут пусто</div>
                  )
                ) : (
                  <PreloadPosts />
                )}
                {postsLoaded && posts.length > 24 && !morePostsLoading && <span ref={postsEndRef} />}
                {postsLoaded && posts.length > 24 && morePostsLoading && (
                  <div className="UI-Loading">
                    <div className="UI-Loader_1"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Обновление 2.2</div>
                <div className="UI-B_CONTENT">
                  <div>• Ключ сессии стал более безопасным.</div>
                  <div>• Улучшена безопасность паролей.</div>
                  <div>• Добавлен раздел «Сессии» в настройках.</div>
                  <div>• Обновлено API авторизации.</div>
                  <div>• Исправление ошибок.</div>
                </div>
              </div>
              {!AccountData.GoldStatus ? (
                <div className="UI-AD_N2-B">
                  <div className="UI-AD_C_TOP">
                    <div className="UI-AD_TITLE">Реклама</div>
                  </div>
                  <div className="UI-AD-T">Подпишитесь на телеграм канал автора сайта</div>
                  <div className="UI-AD_C_BOTTOM">
                    <a className="UI-AD_BTN" href="https://t.me/XaromieChannel">
                      Перейти
                    </a>
                  </div>
                </div>
              ) : null}
              <div className="UI-Block">
                <div className="UI-Title" style={{ width: '100%' }}>
                  Gold пользователи
                </div>
                <div className="GoldSub-Users">
                  <GoldUsers />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
