import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TopBar } from '../../System/Elements/Navigate';
import { aesCreateKey } from '../../System/Modules/Crypto';
import { useModal } from '../../System/Context/Modal';
import useWS from '../../System/Hooks/useWS';
import Chat from './Elements/Chat';
import Chats from './Elements/Chats';

const Messenger = () => {
  const { openModal } = useModal();
  const params = useParams();
  const socket = useWS();
  const username = params.username;

  // Ключ-фраза
  const [selectedKeyword, setSelectedKeyword] = useState(false);
  const [keyword, setKeyword] = useState('');

  // Чаты
  const [chatsLoaded, setChatsLoaded] = useState(false);
  const [chats, setChats] = useState([]);

  // Чат
  const [selectedChat, setSelectedChat] = useState(false);

  useEffect(() => {
    if (socket.ready && localStorage.getItem('M-Keyword')) {
      socket.send({
        type: 'aes_messages_key',
        key: localStorage.getItem('M-Keyword')
      })
    }
  }, [socket.ready]);

  useEffect(() => {
    if (socket.ready) {
      socket.send({
        type: 'load_chats'
      });
    }
  }, [socket.ready]);

  useEffect(() => {
    if (socket.ready) {
      socket.onMessage((data) => {
        switch (data.type) {
          case 'load_chats':
            loadChats(data.content);
            break;
          case 'aes_messages_key':
            handleKeyword(data);
            break;
          case 'load_chat':
            loadChat(data.content);
            break;
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    if (username && selectedKeyword) {
      socket.send({
        type: 'load_chat',
        username: username
      })
    }
  }, [username, selectedKeyword])

  const selectKeyword = () => {
    socket.send({
      type: 'aes_messages_key',
      key: aesCreateKey(keyword)
    });
  }

  const handleKeyword = async (data) => {
    if (data.status === 'verify') {
      setKeyword(data.keyword);
      setSelectedKeyword(true);
      localStorage.setItem('M-Keyword', data.keyword);
    } else {
      openModal({
        type: 'info',
        title: 'Ошибка',
        text: data.content,
      })
    }
  };

  const loadChats = (chats) => {
    setChats(chats);
    setChatsLoaded(true);
  }

  const loadChat = (data) => {
    setSelectedChat(data);
  }

  const deleteAll = () => {
    openModal({
      type: 'query',
      title: 'Вы уверенны что хотите удалить все свои чаты?',
      text: 'Нажимая «Да» вы безвозвратно удалите все свои чаты, при этом ваши сообщения у других пользователей останутся.',
      onNext: () => {
        socket.send({
          type: 'delete_all_chats'
        });
        setChats([]);
      }
    })
  }

  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <div className="Messenger">
          <Chats
            socketReady={socket.ready}
            socketStatus={socket.status}
            chatsLoaded={chatsLoaded}
            chats={chats}
            selectedChat={selectedChat}
          />
          <div className="Chat">
            <Chat
              socket={socket}
              chats={chats}
              setChats={setChats}
              selectedChat={selectedChat}
              keyword={keyword}
            />
            {
              !socket.ready ? (
                <div className="Chat-Error">
                  <div className="Chat-Error_message">
                    Подключение к серверу...
                  </div>
                </div>
              ) : (
                !selectedChat && (
                  <div className="Chat-Error">
                    {
                      !selectedKeyword ? (
                        <div className="Chat-SelectKeyWord">
                          <div className="Title">
                            Ключ-фраза нужна для шифрования вашей истории сообщений,
                            благодаря ней только вы можете получить доступ к своим
                            сообщениям. Если вы вводите ключ-фразу в первый раз,
                            придумайте и запомните её, восстановить её невозможно.
                          </div>
                          <textarea value={keyword} onChange={(e) => { setKeyword(e.target.value) }} placeholder="Введите ключ-фразу" />
                          <div className="Buttons">
                            <button onClick={selectKeyword} className="UI-FormButton">
                              Продолжить
                            </button>
                            <button onClick={deleteAll}>
                              Удалить всё
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="Chat-Error_message">
                          Нет выбранного чата, выберите чат из списка или напишите кому-то.
                        </div>
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Messenger;