const Rules = () => {
    return (
        <div className="UI-Block Info-Block UI-B_FIRST">
            <div className="UI-Title">Политика конфиденциальности и Условия использования</div>

            <div className="BigText">
                <p>Создавая аккаунт в Element, вы принимаете условия использования, и политику конфиденциальности.</p>
                <p>
                    Важно отметить, что при регистрации Вы вводите свою электронную почту для подтверждения того, что Вы реальный
                    человек, мы оставляем за собой право хранить её. Мы используем файлы куки, и локальное хранилище для
                    стабильной работы сайта. Мы не храним ваш IP адрес, и не отслеживаем вашу активность на сайте.
                </p>
                <p>Мессенджер находится на стадии бета-теста, это не доведённый до идеала аспект.</p>
                <p>
                    Мы оставляем за собой право заблокировать аккаунт пользователя, если его деятельность будет нарушать Условия
                    использования.
                </p>
                <br />
                <p>Также, создавая аккаунт Вы принимаете следующие Условия использования (правила):</p>
                <br />
                <div className="Info-BL_R">
                    <div>Создание аккаунта с целью нарушать правила, или распространять незаконный контент</div>
                    <div className="Info-BL_R_IF">Удаление аккаунта и блокировка почты на сайте.</div>
                </div>
                <br />
                <div className="Info-BL_R">
                    <div>Публикация порнографии с участием несовершеннолетних</div>
                    <div className="Info-BL_R_IF">
                        Удаление поста, а так же возможно ограничение в виде запрета на публикацию постов.
                    </div>
                </div>
                <br />
                <div className="Info-BL_R">
                    <div>Распостранение личных данных</div>
                    <div className="Info-BL_R_IF">
                        Удаление поста, а так же ограничение в виде запрета на публикацию постов.
                    </div>
                </div>
                <br />
                <div className="Info-BL_R">
                    <div>
                        Флуд постами, к примеру многочисленные посты которые не несут смысла, и состоят из несвязанных символов
                    </div>
                    <div className="Info-BL_R_IF">
                        Блокировка аккаунта, или ограничение на отправку постов, а так же удаление постов которые нарушают
                        правила.
                    </div>
                </div>
                <br />
                <div className="Info-BL_R">
                    <div>
                        Попытки перегрузки ресурсов, к примеру очень частая выгрузка больших файлов, которые не несут особого
                        смысла
                    </div>
                    <div className="Info-BL_R_IF">
                        Блокировка аккаунта, или ограничение на отправку постов, а также удаление постов которые нарушают
                        правила.
                    </div>
                </div>
                <br />
                Важно отметить, что владелец Element’a не несёт ответственности за медиа, которые публикуются пользователями, например если Вы скачаете файл содержащий вирус который опубликовал другой пользователь.
            </div>
        </div>
    )
}

export default Rules;