import axios from "axios";
import { useEffect, useState } from "react";
import { OLD_API_DOMAIN, Headers } from "../AccountManager";

export const GoldInfo = () => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activated, setActivated] = useState(false);
  const [activationHistory, setActivationHistory] = useState([]);
  useEffect(() => {
    axios
      .get(OLD_API_DOMAIN + "Settings.php?F=GET_GOLD_INFO", {
        headers: Headers,
      })
      .then((res) => {
        if (res.data) {
          setDataLoaded(true);
          setActivated(res.data.activated);
          setActivationHistory(res.data.activation_history);
        }
      });
  }, []);
  return (
    <>
      {dataLoaded ? (
        activated ? (
          <div
            className="Settings-GoldInfoStatus"
            style={{
              backgroundImage: "var(--GOLD_GRADIENT)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            <div className="Status">Активна</div>
          </div>
        ) : (
          <div className="Settings-GoldInfoStatus">
            <div className="Status">Неактивна</div>
          </div>
        )
      ) : (
        <div className="Settings-GoldInfoStatus">
          <div className="Status">Загрузка...</div>
        </div>
      )}
      {activationHistory.length > 0 &&
        activationHistory.map((block, i) => (
          <div key={i} className="Settings-GoldInfoBlock">
            <div className="Status">
              {block.Status === "Active" ? "Активна" : "Неактивна"}
            </div>
            , активировано{" "}
            {new Date(block.Date).toLocaleDateString({
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
          </div>
        ))}
    </>
  );
};
