import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_DOMAIN, useHeaders } from '../AccountManager';
import { I_ANDROID, I_ANONYMOUS, I_APPLE, I_CLOSE, I_SAFARI, I_WINDOWS } from '../../UI/IconPack';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../Context/Modal';

export const Sessions = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const headers = useHeaders();
  const [currentSession, setCurrentSession] = useState('');
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    axios.get(`${API_DOMAIN}/settings/load_sessions`, { headers: headers }).then((res) => {
      if (res.data) {
        if (res.data.sessions.length > 0) {
          setCurrentSession(res.data.current_session);
          setSessions(res.data.sessions.filter((session) => session.id !== res.data.current_session.id));
        }
      }
    })
  }, [])

  const deleteSession = (id) => {
    const deleteRequest = () => {
      axios.post(`${API_DOMAIN}/settings/delete_session`, { session_id: id }, { headers: headers }).then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          setSessions(sessions.filter((session) => session.id !== id));
        } else if (res.data.status === 'error') {
          openModal({
            type: 'info',
            title: t('error'),
            text: res.data.text
          })
        }
      })
    }

    openModal({
      type: 'query',
      title: t('sessions_delete_title'),
      text: t('sessions_delete_text'),
      onNext: () => {
        deleteRequest();
      }
    })
  }

  const HandleSession = ({ session, current }) => {
    let icon;
    let name;
    switch (session.device_type) {
      case 0:
        icon = <I_ANONYMOUS />;
        name = t('sessions_anon');
        break;
      case 1:
        icon = <I_SAFARI />;
        name = t('sessions_browser');
        break;
      case 2:
        icon = <I_ANDROID />;
        name = 'Android';
        break;
      case 3:
        icon = <I_APPLE />;
        name = 'iOS';
        break;
      case 4:
        icon = <I_WINDOWS />;
        name = 'Windows';
        break;
      default:
        icon = <I_ANONYMOUS />;
        name = t('sessions_anon');
    }

    return (
      <div className="UI-PB_C_Element Settings-Session">
        <div className="Icon">
          {icon}
        </div>
        <div className="Info">
          <div className="DeviceType">
            {name}
          </div>
          <div className="Device">
            {session.device}
          </div>
        </div>
        {
          !current && (
            <button onClick={() => { deleteSession(session.id) }}>
              <I_CLOSE />
            </button>
          )
        }
      </div>
    );
  };

  return (
    <>
      {
        currentSession !== '' && (
          <>
            <div className="UI-PartitionName">{t('sessions_current')}</div>
            <div className="UI-PB_Column">
              <HandleSession session={currentSession} current={true} />
            </div>
          </>
        )
      }
      {
        sessions.length > 0 && (
          <>
            <div className="UI-PartitionName">{t('sessions_all')}</div>
            <div className="UI-PB_Column">
              {
                sessions.sort((a, b) => b.id - a.id).map((session) => (
                  <HandleSession key={session.id} session={session} />
                ))
              }
            </div>
          </>
        )
      }
    </>
  )
}
