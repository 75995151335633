import { useEffect, useState } from 'react';
import axios from 'axios';
import { SCRIPTS_DOMAIN } from '../../../System/Elements/AccountManager';

const Updates = () => {
    const [tabActive, setTabActive] = useState(0);
    const [updateList, setUpdateList] = useState([]);
    const [betaUpdates, setBetaUpdates] = useState([]);

    useEffect(() => {
        axios.get(SCRIPTS_DOMAIN + 'GetUpdates.php?Type=Release').then((data) => {
            setUpdateList(data.data);
        });
        axios.get(SCRIPTS_DOMAIN + 'GetUpdates.php?Type=Beta').then((data) => {
            setBetaUpdates(data.data);
        });
    }, [setTabActive, setBetaUpdates]);

    return (
        <div className="UI-ScrollView">
            <div className="UI-Block Info-Block UI-B_FIRST">
                <div className="UI-Title" onClick={() => setTabActive(tabActive === 0 ? 1 : 0)}>
                    История обновлений
                </div>
                <div className="UI-Tabs">
                    <button className={`Tab ${tabActive === 0 ? 'ActiveTab' : ''}`} onClick={() => setTabActive(0)}>
                        Обновления
                    </button>
                    <button className={`Tab ${tabActive === 1 ? 'ActiveTab' : ''}`} onClick={() => setTabActive(1)}>
                        Бета-версии
                    </button>
                </div>
                <div id="UPDATES_HISTORY">
                    {tabActive === 0
                        ? updateList.map((update) => (
                            <div className="Info-UPT_B" key={update.ID}>
                                <div className="Info-UPT_B_T">Обновление {update.Version}</div>
                                <div className="Info-UPT_B_C">{update.Content}</div>
                            </div>
                        ))
                        : betaUpdates.map((update) => (
                            <div className="Info-UPT_B" key={update.ID}>
                                <div className="Info-UPT_B_T">Обновление {update.Version}</div>
                                <div className="Info-UPT_B_C">{update.Content}</div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Updates;