import { useState, useEffect } from 'react';
import { I_Save } from '../UI/IconPack';

export const SavesAvatar = () => (
    <div className="Avatar SavesAvatar">
        <I_Save />
    </div>
);

export const FormButton = ({ onClick, children }) => {
    return (
        <button onClick={onClick} className="UI-FormButton">
            {children}
        </button>
    )
}

export const UploadLoader = () => {
    return (
        <div className="UI-Loader-3">
            <svg
                className="container"
                viewBox="0 0 40 40"
                height="40"
                width="40"
            >
                <circle
                    className="track"
                    cx="20"
                    cy="20"
                    r="17.5"
                    pathLength="100"
                    strokeWidth="5px"
                    fill="none"
                />
                <circle
                    className="car"
                    cx="20"
                    cy="20"
                    r="17.5"
                    pathLength="100"
                    strokeWidth="5px"
                    fill="none"
                />
            </svg>
        </div>
    )
}

export const ProgressRing = ({ progress, size = 100, stroke = 4 }) => {
    const [normalizedRadius, setNormalizedRadius] = useState(0);
    const [circumference, setCircumference] = useState(0);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const calculatedRadius = (size / 2) - stroke;
        const circumference = 2 * Math.PI * calculatedRadius;
        setNormalizedRadius(calculatedRadius);
        setCircumference(circumference);

        const progressOffset = circumference - (progress / 100) * circumference;
        setOffset(isNaN(progressOffset) ? 0 : progressOffset);
    }, [progress, size, stroke]);

    return (
        <svg
            className="Progress"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
        >
            <circle
                stroke="currentColor"
                fill="transparent"
                strokeLinecap="round"
                strokeWidth={stroke}
                strokeDasharray={`${circumference} ${circumference}`}
                style={{ strokeDashoffset: offset }}
                r={normalizedRadius}
                cx={size / 2}
                cy={size / 2}
            />
        </svg>
    );
};
