import { useEffect, useRef, useState } from 'react';
import { TopBar, LeftBar, NavButton } from '../System/Elements/Navigate';
import { HandleAvatar, HandleCover, HandleText, HandleTheme, HandleLinkIcon } from '../System/Elements/Handlers';
import { OLD_API_DOMAIN, useAccountData, useHeaders, GetLinks } from '../System/Elements/AccountManager';
import { Partitions } from '../System/Elements/Partitions';
import { Modals } from '../System/Elements/Modal';
import { I_Email, I_GoldStarGradient, I_INFO, I_Lang, I_Lock, I_PHONE, I_Plus, I_Username, I_Users, I_Warning } from '../System/UI/IconPack';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { UploadLoader } from '../System/Modules/UIKit';
import '../System/UI/ThemePrew.css';
import { useTranslation } from 'react-i18next';

const SettingsIcon = ({ iconBackground, children }) => {
  return (
    <div style={{ background: iconBackground }} className="Icon">
      {children}
    </div>
  );
}

const QuestionModal = ({ input, target, set, changeProfile }) => {
  const variants = {
    show: {
      transform: 'translate(0) scale(1)',
      opacity: 1,
      top: 5,
      zIndex: -1,
      boxShadow: 'rgb(64 62 62) 0px 1px 5px -3px'
    },
    hidden: {
      transform: 'translate(0) scale(0.5)',
      opacity: 0,
      top: -40,
      zIndex: 1,
      boxShadow: '0px 0px 0px 0px rgb(114 114 114 / 50%)'
    },
  }
  return (
    <div className="Settings-Q_container">
      <AnimatePresence>
        {
          input !== target && (
            <motion.div
              className="Question"
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={variants}
            >
              <button className="Apply" onClick={changeProfile}>
                Применить
              </button>
              <button className="Back" onClick={() => set(target)}>
                Отменить
              </button>
            </motion.div>
          )
        }
      </AnimatePresence>
    </div>
  )
}

const Settings = () => {
  const { t } = useTranslation();
  const [AccountData, setAccountData] = useState(useAccountData());
  const headers = useHeaders();
  const coverInputRef = useRef(null);
  const avatarInputRef = useRef(null);
  const [coverUploading, setCoverUploading] = useState(false);
  const [avatarUploading, setAvatarUploading] = useState(false);
  const [name, setName] = useState(AccountData.Name);
  const [description, setDescription] = useState(AccountData.Description);
  const emailRef = useRef(null);
  const [emailHovered, setEmailHovered] = useState(false);
  const PartitionsPanel = useRef();
  const [partitionOpen, setPartitionOpen] = useState(false);
  const [PartitionType, setPartitionType] = useState();
  const [partitionParams, setPartitionParams] = useState(null);
  const [OpenModals, setOpenModals] = useState(false);
  const [ModalData, setModalData] = useState({});

  const [userDataLinks, setUserDataLinks] = useState([]);

  const [typeViewPosts, setTypeViewPosts] = useState(() => {
    const saved = localStorage.getItem('S-PostsType');
    return saved || 'LATEST';
  });

  const [selectedTheme, setSelectedTheme] = useState(localStorage.getItem('S-Theme') || 'LIGHT');
  let themes = [
    {
      name: 'Светлая',
      id: 'LIGHT',
      class: 'Theme-Light',
      goldStatus: false,
    },
    {
      name: 'Золотая',
      id: 'GOLD',
      class: 'Theme-Gold',
      goldStatus: true,
    },
    {
      name: 'Тёмная',
      id: 'DARK',
      class: 'Theme-Dark',
      goldStatus: false,
    },
    {
      name: 'Золотая тёмная',
      id: 'GOLD-DARK',
      class: 'Theme-Gold-Dark',
      goldStatus: true,
    },
    {
      name: 'AMOLED',
      id: 'AMOLED',
      class: 'Theme-Amoled',
      goldStatus: false,
    },
    {
      name: 'Золотая AMOLED',
      id: 'AMOLED-GOLD',
      class: 'Theme-Amoled-Gold',
      goldStatus: true,
    },
  ];
  themes = AccountData.GoldStatus ? themes : themes.filter((t) => t.goldStatus === false);

  useEffect(() => {
    localStorage.setItem('S-PostsType', typeViewPosts);
  }, [typeViewPosts]);
  useEffect(() => {
    const fetchLinks = async () => {
      await GetLinks({
        setUserDataLinks: setUserDataLinks,
        userDataLinks: userDataLinks,
        handlePartitionClick: handlePartitionClick,
      });
    };
    fetchLinks();
  }, []);

  const handlePartitionClick = ({ type, link, params }) => {
    setPartitionOpen(true);
    setPartitionType({ type, link });
    setPartitionParams(params);
  };

  const updateLink = (updatedLink) => {
    setUserDataLinks((prevLinks) => prevLinks.map((link) => (link.id === updatedLink.id ? updatedLink : link)));
  };

  const changeCover = () => {
    setCoverUploading(true);
    const file = coverInputRef.current.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('Cover', file);
      axios.post(`${OLD_API_DOMAIN}/Settings.php?F=CP_UPLOAD_COVER`, formData, { headers: headers, }).then((res) => {
        if (res.data) {
          setCoverUploading(false);
          const newData = { ...AccountData, Cover: res.data.Content };
          setAccountData(newData);
          localStorage.setItem('UserData', JSON.stringify(newData));
        }
      })
    }
  }

  const deleteCover = () => {
    axios.get(`${OLD_API_DOMAIN}/Settings.php?F=DELETE_COVER`, { headers: headers }).then((res) => {
      const newData = { ...AccountData, Cover: null };
      setAccountData(newData);
      localStorage.setItem('UserData', JSON.stringify(newData));
    })
  }

  const changeAvatar = () => {
    setAvatarUploading(true);
    const file = avatarInputRef.current.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('Avatar', file);
      axios.post(`${OLD_API_DOMAIN}/Settings.php?F=CP_UPLOAD_AVATAR`, formData, { headers: headers, }).then((res) => {
        if (res.data) {
          setAvatarUploading(false);
          const newData = { ...AccountData, Avatar: res.data.Content };
          setAccountData(newData);
          localStorage.setItem('UserData', JSON.stringify(newData));
        }
      })
    }
  }

  const deleteAvatar = () => {
    axios.get(`${OLD_API_DOMAIN}/Settings.php?F=DELETE_AVATAR`, { headers: headers }).then((res) => {
      const newData = { ...AccountData, Avatar: null };
      setAccountData(newData);
      localStorage.setItem('UserData', JSON.stringify(newData));
    })
  }

  const changeProfile = () => {
    if (name !== AccountData.Name) {
      const data = {
        Name: name
      }
      axios.post(`${OLD_API_DOMAIN}/Settings.php?F=CHANGE_NAME`, data, { headers: headers }).then((res) => {
        const newData = { ...AccountData, Name: name };
        setAccountData(newData);
        localStorage.setItem('UserData', JSON.stringify(newData));
      })
    }
    if (description !== AccountData.Description) {
      const data = {
        Description: description
      }
      axios.post(`${OLD_API_DOMAIN}/Settings.php?F=CHANGE_DEC`, data, { headers: headers }).then((res) => {
        const newData = { ...AccountData, Description: description };
        setAccountData(newData);
        localStorage.setItem('UserData', JSON.stringify(newData));
      })
    }
  }

  const showEmail = () => {
    setEmailHovered(true);
  }
  const hideEmail = () => {
    setEmailHovered(false);
  }

  const setTheme = (theme) => {
    localStorage.setItem('S-Theme', theme);
    HandleTheme();
    setSelectedTheme(theme);
  };

  return (
    <>
      {OpenModals ? <Modals data={ModalData} setOpenModals={setOpenModals} /> : null}
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="Settings-Page UI-PAGE_BODY">
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block Profile-InfoBlock UI-B_FIRST">
                <div className="UI-Title" style={{ width: '100%' }}>
                  {t('my_profile')}
                </div>

                <div className="Profile-Cover">
                  <HandleCover cover={AccountData.Cover} />
                </div>
                <div className="Avatar">
                  <HandleAvatar avatar={AccountData.Avatar} name={AccountData.Username} />
                </div>

                <div className="Name">
                  <div>{AccountData.Name}</div>
                  <div className="UserIcons"></div>
                </div>

                <div className="IconInfoContainer">
                  <div className="Info"></div>
                </div>

                <div className="Username">@{AccountData.Username}</div>
                <div
                  onMouseEnter={showEmail}
                  onMouseLeave={hideEmail}
                  className={`Settings-PRFL_Email${emailHovered ? ' hover' : ''}`}
                  ref={emailRef}
                >
                  {AccountData.Email}
                </div>
                {AccountData.Description && (
                  <div className="Description">
                    <div className="Title">{t('description')}</div>
                    <div className="Text">
                      <HandleText text={AccountData.Description} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="UI-C_L" ref={PartitionsPanel}>
            <div id="SETTINGS-BODY" className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">{t('edit_profile_title')}</div>
                <div className="Settings-CP_Cover">
                  <div className="Cover">
                    <HandleCover cover={AccountData.Cover} />
                    {
                      coverUploading && (
                        <UploadLoader />
                      )
                    }
                  </div>
                  <div className="Settings-ChangeButtons">
                    <input
                      id="S-CP_UPLOAD_COVER"
                      type="file"
                      accept="image/*"
                      autoComplete="off"
                      ref={coverInputRef}
                      onChange={changeCover}
                    />
                    <label htmlFor="S-CP_UPLOAD_COVER" className="Button">
                      {t('upload_button')}
                    </label>
                    <button onClick={deleteCover} className="ButtonDL">
                      {t('delete_button')}
                    </button>
                  </div>
                </div>
                <div className="Settings-CP_Avatar">
                  <div className="Avatar">
                    <HandleAvatar avatar={AccountData.Avatar} name={AccountData.Username} />
                    {
                      avatarUploading && (
                        <UploadLoader />
                      )
                    }
                  </div>
                  <div className="Settings-ChangeButtons">
                    <form id="S-CP_UPLOAD_AVATAR_FORM" encType="multipart/form-data">
                      <input
                        id="S-CP_UPLOAD_AVATAR"
                        type="file"
                        accept="image/*"
                        ref={avatarInputRef}
                        onChange={changeAvatar}
                      />
                    </form>
                    <label htmlFor="S-CP_UPLOAD_AVATAR" className="Button">
                      {t('upload_button')}
                    </label>
                    <button onClick={deleteAvatar} className="ButtonDL">
                      {t('delete_button')}
                    </button>
                  </div>
                </div>
                <div className="Settings-CP_Input_container">
                  <div className="Settings-CP_IC_Title">имя (псевдоним)</div>
                  <input
                    className="UI-Input"
                    placeholder="Введите текст"
                    value={name}
                    autoComplete="off"
                    type="text"
                    maxLength="30"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <QuestionModal input={name} target={AccountData.Name} set={setName} changeProfile={changeProfile} />
                </div>
                <div id="S-CP_DecContainer" className="Settings-CP_Input_container">
                  <div className="Settings-CP_IC_Title">описание профиля</div>
                  <textarea
                    className="UI-Input"
                    placeholder="Введите текст"
                    value={description}
                    autoComplete="off"
                    type="text"
                    maxLength="100"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                  <QuestionModal input={description} target={AccountData.Description} set={setDescription} changeProfile={changeProfile} />
                </div>
                {AccountData.GoldStatus === true && (
                  <div className="Settings-CP_Input_container">
                    <div className="Settings-CP_IC_Title">ссылки</div>
                    <div className="Settings-Links">
                      <button
                        onClick={() => {
                          handlePartitionClick({ type: 'add_link' });
                        }}
                      >
                        <I_Plus />
                        {t('add_button')}
                      </button>

                      {userDataLinks.map((link, i) => (
                        <button
                          key={i}
                          className="Link"
                          onClick={() => {
                            handlePartitionClick({
                              type: 'edit_link',
                              link,
                              updateLink,
                            });
                          }}
                        >
                          <HandleLinkIcon link={link.Link} />
                          {link.Title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="UI-PartitionName">{t('partition_account')}</div>
              <div className="UI-Buttons">
                <button onClick={() => handlePartitionClick({ type: 'change_username', params: { accountData: AccountData, setAccountData: setAccountData } })}>
                  <SettingsIcon iconBackground="rgb(71 42 221)">
                    <I_Username />
                  </SettingsIcon>
                  {t('change_username')}
                </button>
              </div>
              <div className="UI-PartitionName">{t('partition_confidentiality')}</div>
              <div className="UI-Buttons">
                <button id="PARTITION-SESSIONS" onClick={() => handlePartitionClick({ type: 'sessions' })}>
                  <SettingsIcon iconBackground="rgb(12 227 0)">
                    <I_PHONE />
                  </SettingsIcon>
                  Сессии
                </button>
                <button onClick={() => handlePartitionClick({ type: 'change_email' })}>
                  <SettingsIcon iconBackground="rgb(255 82 82)">
                    <I_Email />
                  </SettingsIcon>
                  {t('change_email')}
                </button>
                <button onClick={() => handlePartitionClick({ type: 'change_password' })}>
                  <SettingsIcon iconBackground="rgb(71 42 221)">
                    <I_Lock />
                  </SettingsIcon>
                  {t('change_password')}
                </button>
              </div>
              <div className="UI-PartitionName">{t('partition_other')}</div>
              <div className="UI-Buttons">
                <button onClick={() => handlePartitionClick({ type: 'gold_info' })}>
                  <SettingsIcon iconBackground="linear-gradient(465deg, #fab31e, #fd9347)">
                    <I_GoldStarGradient startColor="#FFF" stopColor="#FFF" />
                  </SettingsIcon>
                  {t('subscribe_gold')}
                </button>
                <button onClick={() => handlePartitionClick({ type: 'profile_status' })}>
                  <SettingsIcon iconBackground="var(--ACCENT_COLOR)">
                    <I_Warning />
                  </SettingsIcon>
                  {t('my_status')}
                </button>
                <button onClick={() => handlePartitionClick({ type: 'change_language' })}>
                  <SettingsIcon iconBackground="rgb(76 107 204)">
                    <I_Lang />
                  </SettingsIcon>
                  {t('language')}
                </button>
                <button onClick={() => handlePartitionClick({ type: 'authors' })}>
                  <SettingsIcon iconBackground="rgb(76 107 204)">
                    <I_Users />
                  </SettingsIcon>
                  {t('authors')}
                </button>
                <NavButton to="/info/advantages">
                  <SettingsIcon iconBackground="rgb(101 85 180)">
                    <I_INFO />
                  </SettingsIcon>
                  {t('info')}
                </NavButton>
              </div>
              <div className="UI-PartitionName">{t('partition_posts_type')}</div>
              <div className="UI-Block">
                <div className="Settings-PType">
                  {['LATEST', 'REC', 'SUBSCRIPTIONS'].map((type) => (
                    <button
                      key={type}
                      ptype={type}
                      className={typeViewPosts === type ? 'Active' : ''}
                      onClick={() => setTypeViewPosts(type)}
                    >
                      {type === 'LATEST' ? t('category_last') : type === 'REC' ? t('category_recommended') : t('category_subscriptions')}
                    </button>
                  ))}
                </div>
              </div>
              <div className="UI-PartitionName">{t('partition_change_theme')}</div>
              <div className="UI-Block">
                <div className="Settings-Themes">
                  {themes.map((theme, i) => (
                    <div key={i} className={`${theme.class} ChangeTheme`} onClick={() => setTheme(theme.id)}>
                      <div className="TH-Container">
                        <div className="TH-TopBar"></div>
                        <div className="TH-Posts">
                          <div className="TH-AddPost">
                            <div className="TH-Button"></div>
                          </div>
                          <div className="TH-Post"></div>
                          <div className="TH-Post"></div>
                        </div>
                        <div className="TH-BottomBar"></div>
                      </div>
                      <div className={`Info${selectedTheme === theme.id ? ' Selected' : ''}`}>{theme.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {partitionOpen ? (
              <Partitions
                setPartitionOpen={setPartitionOpen}
                propsPartitionType={PartitionType}
                params={partitionParams}
                setOpenModals={setOpenModals}
                setModalData={setModalData}
                //##################
                userDataLinks={userDataLinks}
                setUserDataLinks={setUserDataLinks}
              //Добавил эти 2 параметра так как хароми их забыл передать
              // это исправляет ошибку с неизвестным useState
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
