import React, { useState, useEffect, useRef } from 'react';
import { OLD_API_DOMAIN, CDN_DOMAIN, useAccountData, useHeaders } from '../System/Elements/AccountManager';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { NavButton, TopBar } from '../System/Elements/Navigate';
import { PreloadPosts } from '../System/UI/Preload';
import { HandleCover, HandleAvatar, HandleLinkIcon, HandleUserBlock, HandlePost, HandleText } from '../System/Elements/Handlers';
import ErrorPage from '../Pages/ErrorPage';
import { Window } from '../System/Elements/Modal';
import { I_Dots, I_Megaphone, I_Messenger } from '../System/UI/IconPack';
import axios from 'axios';
import { FastAverageColor } from 'fast-average-color';
import { hexToRgba } from '../System/Elements/Function';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Profile = () => {
    const { t } = useTranslation();
    const AccountData = useAccountData();
    const headers = useHeaders();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const username = params.username;
    const postsRef = useRef(null);
    const subscribeButtonRef = useRef(null);
    const [profileHidden, setProfileHidden] = useState(false);
    const [profileData, setProfileData] = useState('');
    const [coverIsset, setCoverIsset] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [postsLoaded, setPostsLoaded] = useState(false);
    const [postsSI, setPostsSI] = useState(25);
    const [morePostsLoading, setMorePostsLoading] = useState(false);
    const [allPostsLoaded, setAllPostsLoaded] = useState(false);
    const [posts, setPosts] = useState([]);
    const [accentColor, setAccentColor] = useState(null);
    const [activeTab, setActiveTab] = useState(null);

    const [windowOpen, setWindowOpen] = useState(false);
    const [windowTitle, setWindowTitle] = useState('');
    const [windowContent, setWindowContent] = useState('');

    const variants = {
        initial: {
            opacity: 0,
            marginLeft: '-50%'
        },
        animate: {
            opacity: 1,
            marginLeft: 0
        },
        exit: {
            opacity: 0,
            marginLeft: '-50%'
        },
    }

    useEffect(() => {
        if (dataLoaded) {
            setCoverIsset(true);
            setDataLoaded(false);
            setPosts([]);
            setPostsLoaded(false);
        }
        const currentTab = location.pathname.replace(/^\/e\/[^/]+\//, '');
        switch (currentTab) {
            case 'info': setActiveTab('info'); break;
            default: setActiveTab('e'); break;
        };
        axios.get(`${OLD_API_DOMAIN}/Profile.php?Username=${username}`, { headers: headers }).then((response) => {
            let profile = response.data;
            let uid = profile.ID;
            let targetType = profile.type === 'user' ? 0 : 1;
            if (uid) {
                setProfileData(profile);
                setSubscribed(profile.Subscribed);
                if (profile.Avatar === null || profile.Avatar === 'None') {
                    if (profile.Cover === null || profile.Cover === 'None') {
                        setCoverIsset(false);
                    }
                }
                if (profile.Posts > 0) {
                    const data = {
                        target_id: uid,
                        target_type: targetType
                    };
                    axios.post(`${OLD_API_DOMAIN}/LoadPosts.php?F=USER`, data, {
                        headers: headers,
                    }).then(res => {
                        setPosts(res.data);
                        setPostsLoaded(true);
                    })
                } else {
                    setPostsLoaded(true);
                }
            }
            setDataLoaded(true);
            if (profile.Avatar && profile.Avatar !== 'None') {
                try {
                    const fac = new FastAverageColor();
                    fac.getColorAsync(`${CDN_DOMAIN}/Content/Avatars/${profile.Avatar}`)
                        .then((color) => {
                            setAccentColor(color.hex);
                        })
                } catch {
                    setAccentColor(null);
                }
            }
        });
    }, [username])

    const toggleSubscribe = () => {
        axios.post(`${OLD_API_DOMAIN}/Subscriptions.php?F=SUB_TO_USER`, { username: profileData.Username }, { headers: headers, });
        setSubscribed(!subscribed);
    }

    const viewSubscribed = () => {
        if (profileData.Subscriptions > 0) {
            axios.post(`${OLD_API_DOMAIN}/Subscriptions.php?F=GET_SUBSCRIPTIONS`, { username: profileData.Username }, { headers: headers, }).then((res) => {
                if (res.data.length > 0) {
                    setWindowTitle('Подписки');
                    setWindowContent(
                        <div className="Profile-Subs UI-ScrollView">
                            {
                                res.data.map((user, i) => (
                                    <HandleUserBlock key={i} user={user} />
                                ))
                            }
                        </div>
                    );
                    setWindowOpen(true);
                }
            });
        }
    }

    const viewSubscribes = () => {
        if (profileData.Subscribers > 0) {
            axios.post(`${OLD_API_DOMAIN}/Subscriptions.php?F=GET_SUBSCRIBERS`, { username: profileData.Username }, { headers: headers, }).then((res) => {
                if (res.data.length > 0) {
                    setWindowTitle('Подписчики');
                    setWindowContent(
                        <div className="Profile-Subs UI-ScrollView">
                            {
                                res.data.map((user, i) => (
                                    <HandleUserBlock key={i} user={user} />
                                ))
                            }
                        </div>
                    );
                    setWindowOpen(true);
                }
            });
        }
    }

    const tabClick = (tab) => {
        switch (tab) {
            case 'info': setActiveTab('info'); navigate('info'); break;
            default: setActiveTab('e'); navigate(`/e/${username}`); break;
        };
    }

    const { ref: postsEndRef, inView: postsEndIsView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        const handleScroll = () => {
            if (postsRef.current.scrollTop > 250) {
                setProfileHidden(true);
            } else {
                setProfileHidden(false);
            }
        };
        const element = postsRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, [])

    useEffect(() => {
        if (postsEndIsView) {
            setMorePostsLoading(true);
            const targetType = profileData.type === 'user' ? 0 : 1;
            const data = {
                target_id: profileData.ID,
                target_type: targetType,
                StartIndex: postsSI
            };
            axios.post(`${OLD_API_DOMAIN}/LoadPosts.php?F=USER`, data, {
                headers: headers,
            }).then(res => {
                if (Array.isArray(res.data) && res.data.length > 0) {
                    setPosts(prevPosts => [...prevPosts, ...res.data]);
                    setPostsSI(prevPostsSI => prevPostsSI + 25);
                    setMorePostsLoading(false);
                } else {
                    setAllPostsLoaded(true);
                    setMorePostsLoading(false);
                }
            });
        }
    }, [postsEndIsView]);

    return (
        <>
            <TopBar search={true} />
            <div className="Content Profile-Page">
                {
                    dataLoaded && profileData === '' ? (
                        <ErrorPage />
                    ) : (
                        <>
                            <AnimatePresence>
                                {
                                    !profileHidden && (
                                        <motion.div
                                            className="UI-C_L"
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            variants={variants}
                                        >
                                            <div className="UI-ScrollView">

                                                <div className="UI-Block Profile-InfoBlock UI-B_FIRST">

                                                    <div style={{ opacity: !coverIsset ? '0' : '', height: !coverIsset ? '100px' : '', background: accentColor ? `linear-gradient(20deg, ${hexToRgba(accentColor, 1)}, rgb(161 157 177))` : 'var(--NL_GRADIENT)' }} className="Profile-Cover">{profileData !== '' ? <HandleCover cover={profileData.Cover} /> : <div className="UI-PRELOAD"></div>}</div>
                                                    <div style={{ top: !coverIsset ? '48px' : '' }} className="Avatar">{profileData !== '' ? <HandleAvatar avatar={profileData.Avatar} name={profileData.Name} /> : <div className="UI-PRELOAD"></div>}</div>

                                                    <div className="Name">
                                                        {profileData.Name ? (
                                                            <>
                                                                {profileData.Name}
                                                                {profileData.type === 'channel' && <div className="UI-UserIcons"><I_Megaphone /></div>}
                                                                {profileData.Icons && <div className="UI-UserIcons" dangerouslySetInnerHTML={{ __html: profileData.Icons }}></div>}
                                                            </>
                                                        ) : (
                                                            <div className="UI-PRELOAD" style={{ width: '100px', height: '15px' }}></div>
                                                        )}
                                                    </div>
                                                    <div className="IconInfoContainer">
                                                        <div className="Info"></div>
                                                    </div>
                                                    <div className="Username">{profileData.Username ? '@' + profileData.Username : <div className="UI-PRELOAD" style={{ width: '120px', height: '15px' }}></div>}</div>

                                                    {dataLoaded && (
                                                        profileData.type === 'user'
                                                            ? AccountData.ID !== profileData.ID
                                                            : true
                                                    ) && (
                                                            <div style={{ position: 'relative' }}>
                                                                <div className="ButtonsContainer">
                                                                    <div className="Buttons">
                                                                        {
                                                                            subscribed ? (
                                                                                <button onClick={toggleSubscribe} ref={subscribeButtonRef} className="Button">{t('unsubscribe_button')}</button>
                                                                            ) : (
                                                                                <button onClick={toggleSubscribe} ref={subscribeButtonRef} className="SubButton Button">{t('subscribe_button')}</button>
                                                                            )
                                                                        }
                                                                        {
                                                                            profileData.type === 'user' && (
                                                                                <NavButton to={`/chat/${profileData.Username}`} className="Button"><I_Messenger /></NavButton>
                                                                            )
                                                                        }
                                                                        {
                                                                            AccountData?.Permissions?.Admin === true && (
                                                                                <button className="Button"><I_Dots /></button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    <div className="Info">
                                                        {
                                                            profileData.type !== 'channel' && (
                                                                <button onClick={viewSubscribed} className="Container">
                                                                    <div className="Value">{dataLoaded ? profileData.Subscriptions : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                                    <div className="Title">подписок</div>
                                                                </button>
                                                            )
                                                        }
                                                        <button onClick={viewSubscribes} className="Container">
                                                            <div className="Value">{dataLoaded ? profileData.Subscribers : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                            <div className="Title">подписчиков</div>
                                                        </button>
                                                        <div className="Container">
                                                            <div className="Value">{dataLoaded ? profileData.Posts : <div className="UI-PRELOAD" style={{ width: '40px', height: '15px' }}></div>}</div>
                                                            <div className="Title">постов</div>
                                                        </div>
                                                    </div>
                                                    {profileData.Description &&
                                                        <div className="Description">
                                                            <div className="Title">{t('description')}</div>
                                                            <div className="Text">
                                                                <HandleText text={profileData.Description} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {profileData.Links && profileData.Links.length > 0 &&
                                                        <div className="Links">
                                                            {profileData.Links.map((link, i) => (
                                                                <button key={i} onClick={() => window.open(link.Link, '_blank')} className="Link">
                                                                    <HandleLinkIcon link={link.Link} /> {link.Title}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </motion.div>
                                    )
                                }
                            </AnimatePresence>
                            <div className="UI-C_R Profile-Posts">
                                <div ref={postsRef} className="UI-ScrollView">
                                    <div className="Posts" style={{ marginBottom: 20 }}>
                                        <div className="UI-Tabs UI-B_FIRST">
                                            <button className={`Tab${activeTab == `e` ? ' ActiveTab' : ''}`} onClick={() => tabClick('e')} >Посты</button>
                                            <button className={`Tab${activeTab == `info` ? ' ActiveTab' : ''}`} onClick={() => tabClick('info')} >Доп. информация</button>
                                        </div>
                                        <Routes>
                                            <Route path="/" element={
                                                <>
                                                    {AccountData.ID ? (
                                                        postsLoaded ? (
                                                            posts.length > 0 ? (
                                                                posts.map((post, i) => (
                                                                    <HandlePost key={i} post={post} profileData={profileData} />
                                                                ))
                                                            ) : (
                                                                <div className="UI-ErrorMessage">Ой, а тут пусто</div>
                                                            )
                                                        ) : (
                                                            <PreloadPosts />
                                                        )
                                                    ) : (
                                                        <div className="UI-ErrorMessage">Для просмотра постов нужно иметь аккаунт</div>
                                                    )}
                                                    {
                                                        !morePostsLoading && postsLoaded && posts.length > 0 && !allPostsLoaded && (
                                                            <span ref={postsEndRef} />
                                                        )
                                                    }
                                                    {
                                                        postsLoaded && morePostsLoading && (
                                                            <div className="UI-Loading">
                                                                <div className="UI-Loader_1"></div>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            } />
                                            <Route path="info" element={
                                                <div id="ProfileInfo">
                                                    <div className="UI-Block">
                                                        <div id="PROFILE_REG_DATE">
                                                            Дата регистрации: {profileData.CreateDate}
                                                        </div>
                                                    </div>
                                                </div>
                                            } />
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                            <Window title={windowTitle} content={windowContent} style={{ maxWidth: 400 }} contentStyle={{ height: '50vh' }} isOpen={windowOpen} setOpen={setWindowOpen} />
                        </>
                    )
                }
            </div>
        </>
    )
};

export default Profile;
