import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  OLD_API_DOMAIN,
  useAccountData,
  useHeaders
} from '../System/Elements/AccountManager';
import { TopBar } from '../System/Elements/Navigate';
import { PreloadPost, PreloadComments } from '../System/UI/Preload';
import { HandlePost, HandleComment } from '../System/Elements/Handlers';
import { I_CLOSE, I_Send, I_AddFile } from '../System/UI/IconPack';
import axios from 'axios';
import ErrorPage from '../Pages/ErrorPage';
import { AnimateElement } from '../System/Elements/Function';

const Post = () => {
  const accountData = useAccountData();
  const headers = useHeaders();
  const params = useParams();
  const [post, setPost] = useState('');
  const [commentsLoaded, setCommentsLoaded] = useState(false);
  const [comments, setComments] = useState([]);
  const fileRefs = useRef({});
  const [commentFiles, setCommentFiles] = useState([]);
  const commentInputRef = useRef(null);
  const [commentReply, setCommentReply] = useState(null);

  const loadComments = async ({ PostID }) => {
    const res = await axios.post(`${OLD_API_DOMAIN}/PostInteraction.php?F=LOAD_COMMENTS`, { PostID: PostID }, { headers: headers });
    if (res.data) {
      return res.data;
    } else {
      return false;
    }
  };

  useEffect(() => {
    axios.post(`${OLD_API_DOMAIN}/LoadPost.php`,
      { PostID: params.id },
      {
        headers: headers,
      }
    )
      .then((res) => {
        let post = res.data;
        if (post.ID) {
          setPost(post);
          if (accountData.ID) {
            if (post.Comments > 0) {
              loadComments({ PostID: post.ID }).then((data) => {
                setComments(data);
                setCommentsLoaded(true);
              });
            } else {
              setCommentsLoaded(true);
            }
          }
        } else {
          setPost('Error');
        }
      });
  }, [params]);

  const handleFilesInput = (e) => {
    setCommentFiles(e.target.files);
  };

  const handleFileRemove = async (i) => {
    const updatedFiles = Array.from(commentFiles).filter(
      (_, index) => index !== i
    );
    AnimateElement(fileRefs.current[i], 'FILE_INPUT-DELETE', 0.2);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setCommentFiles(updatedFiles);
  };

  const sendComment = () => {
    if (commentInputRef.current.value == null || commentInputRef.current.value == '') return;
    const formData = new FormData();
    formData.append('Text', commentInputRef.current.value);
    formData.append('PostID', post.ID);
    if (commentReply) {
      formData.append('Reply', commentReply.id);
    };
    if (commentFiles.length > 0) {
      for (let i = 0; i < commentFiles.length; i++) {
        formData.append('Files[]', commentFiles[i]);
      }
    }
    axios.post(`${OLD_API_DOMAIN}/PostInteraction.php?F=POST_COMMENT`, formData,
      {
        headers: headers,
      }
    ).then((res) => {
      const data = res.data;
      if (data.Type == 'Verify') {
        loadComments({ PostID: post.ID }).then((data) => {
          setComments(data);
        });
        commentInputRef.current.value = '';
        setCommentFiles([]);
        setCommentReply(null);
      }
    })
  };

  const handleReplyClick = (data) => {
    setCommentReply(data);
  };

  const handleCommentDelete = () => {
    loadComments({ PostID: post.ID }).then((data) => {
      setComments(data);
    });
  };

  return (
    <>
      <TopBar search={true} />
      <div className="Content Post-Page">
        {
          post !== 'Error' ? (
            <>
              <div className="UI-C_L" id="POST-CONTAINER">
                <div className="UI-ScrollView">
                  {post !== '' ? (
                    <HandlePost post={post} className="UI-B_FIRST" />
                  ) : (
                    <PreloadPost className="UI-B_FIRST" />
                  )}
                </div>
              </div>
              <div className="UI-C_R Post-Comments">
                <div className="UI-ScrollView">
                  {accountData.ID ? (
                    <>
                      <div className="UI-PartitionName UI-B_FIRST">Комментарии</div>
                      <div className="Post-Add_comment" style={commentReply ? { borderRadius: 'var(--BR_BASE)' } : {}}>
                        {commentReply &&
                          <div id="COMMENT-REPLY" className="Reply" style={{ display: 'flex' }}>
                            <div className="ReplyContent">
                              <div className="Name">
                                В ответ {commentReply.name}
                                {commentReply.icons && <div className="UI-UserIcons" dangerouslySetInnerHTML={{ __html: commentReply.icons }} />}
                              </div>
                              <div className="Text">{commentReply.text}</div>
                            </div>
                            <button className="Close" onClick={() => setCommentReply(null)}>
                              <I_CLOSE />
                            </button>
                          </div>
                        }
                        <div className="Input">
                          <input
                            placeholder="Комментировать..."
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                sendComment();
                              }
                            }}
                            maxLength="3400"
                            ref={commentInputRef}
                          />
                          <input
                            id="COMMENT-FILES_INPUT"
                            type="file"
                            multiple
                            hidden
                            onChange={handleFilesInput}
                          />
                          <label htmlFor="COMMENT-FILES_INPUT" className="AddFile">
                            <I_AddFile />
                          </label>
                          <button className="Send" onClick={sendComment}>
                            <I_Send />
                          </button>
                        </div>
                      </div>
                      <div style={{ width: '100%', position: 'relative' }}>
                        {commentFiles.length > 0 && (
                          <div
                            id="COMMENT-FILES_LIST"
                            className="UI-UniversalPanel ELEMENT-SHOW"
                            style={{
                              right: 0,
                              animation:
                                '0.2s ease 0s 1 normal forwards running ELEMENT-SHOW',
                            }}
                          >
                            {Array.from(commentFiles).map((file, i) => (
                              <div
                                key={file.name}
                                ref={(el) => {
                                  fileRefs.current[i] = el;
                                }}
                                className="Item"
                              >
                                <div className="Name">{file.name}</div>
                                <button
                                  className="Close"
                                  onClick={() => handleFileRemove(i)}
                                >
                                  <I_CLOSE />
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        {
                          accountData.ID ? (
                            commentsLoaded ? (
                              comments.length > 0 ? (
                                comments.map((comment, i) => (
                                  <HandleComment key={i} comment={comment} postID={post.ID} onReplyClick={handleReplyClick} onDelete={handleCommentDelete} />
                                ))
                              ) : (
                                <div className="UI-ErrorMessage">
                                  Пока что никто не оставил комментарий..
                                </div>
                              )
                            ) : (
                              <PreloadComments />
                            )
                          ) : (
                            <div className="UI-ErrorMessage">
                              Чтобы смотреть комментарии, вам необходимо иметь аккаунт
                            </div>
                          )
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="UI-B_FIRST" style={{ height: '1px' }}></div>
                      <div className="UI-ErrorMessage">
                        Для просмотра комментариев нужно иметь аккаунт
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <ErrorPage />
          )
        }
      </div>
    </>
  );
};

export default Post;
