import { useState } from 'react';
import { I_Username } from '../../UI/IconPack';
import { OLD_API_DOMAIN, useHeaders } from '../AccountManager';
import axios from 'axios';

const ChangeUsername = ({ params }) => {
    const accountData = params.accountData;
    const headers = useHeaders();
    const [username, setUsername] = useState(params.accountData.Username);

    const change = () => {
        axios.post(`${OLD_API_DOMAIN}/Settings.php?F=CHANGE_USERNAME`, { username: username }, { headers: headers }).then((res) => {
            if (res.data) {
                if (res.data.Type === 'Verify') {
                    const newData = {...accountData, Username: username };
                    params.setAccountData(newData);
                    localStorage.setItem('UserData', JSON.stringify(newData));
                }
            }
        })
    }

    return (
        <>
            <div className="Settings-UsernameIcon">
                <I_Username />
            </div>
            <div className="UI-PB_InputText">
                Сменить имя можно сколько угодно раз, но если его займёт кто-то другой вернуть уже не получиться.
            </div>
            <input value={username} onChange={(e) => { setUsername(e.target.value) }} placeholder="@введите_текст" type="text" autoComplete="off" />
            <button onClick={change} className="UI-PB_NextButton">Сменить</button>
        </>
    );
};

export default ChangeUsername;
